import React, { useEffect, useState } from 'react';
import { checkRoutePermissions } from '../../utils/permission';
import NotAccess from '../NotAccess';
import avatar from "../../assets/avatar_student.png";
import GHeader from '../../components/GeneralHeader/GHeader';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircle } from "react-icons/io";
import { Button, Select, Spin, Table,Flex } from 'antd';
import PlannerModal from '../../components/Modals/PlanerModal/PlannerModal';
import { IoEyeOutline } from "react-icons/io5";
import { FaRegCheckCircle } from 'react-icons/fa';
import "../../styles/pages/studentPlanner.css";
import StudentPlannerModal from '../../components/Modals/studentPlanner/studentPlanner';
import { getPlanner } from '../../store/services/studentPlanner';
import { useAppSelector } from '../../store/hooks';
import { fetchStudent, getPlannerClasses } from '../../store/services/PlannerModal';
import { Link, useNavigate } from 'react-router-dom';


const StudentPlanner = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const [loading,setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [student, setStudent] = useState([]);
  const [classId, setClassId] = useState<null | number>(null);
  const [plannerData, setPlannerData] = useState([]);
  const [data, setData] = useState([
    { day: "Monday", subject: "text", work: "work", due: "24-10-10", done: true },
    { day: "Monday", subject: "text", work: "work", due: "24-05-10", done: false },
    { day: "Monday", subject: "text", work: "work", due: "24-20-10", done: true },
    { day: "Tuesday", subject: "text", work: "work", due: "24-20-10", done: true },
    { day: "Tuesday", subject: "text", work: "work", due: "24-20-10", done: true },
    { day: "Wednesday", subject: "text", work: "work", due: "24-20-10", done: false },
  ]);

  const { studentPlanners } = useAppSelector(state => state.planner);

  let lastDay: any = null;



  const columns = [
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
      render: (text: any, record: any, index: any) => {
        return (
          <div className='tab_left'>{text}</div>
        )
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text: any, record: any) => (
        <div className='tab_left'>{text}</div>
      )
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: ((text: any, record: any) => (
        <div className='tab_left'>{text}</div>
      ))
    },
    {
      title: "Detials",
      render: (text: any,record:any) => {
        return (
          <div  style={{marginLeft:"20px"}}>
            {/* <Link to={`/planner/details/${record.studentId}/${record.classId}`}> */}
            <IoEyeOutline cursor={"pointer"} fontSize={"20px"} onClick={() => {
              navigate("/planner/details/student-details", {
                state: record
              })
            }}/>
            {/* </Link> */}
          </div>
        )
      }
    },
  ];

  const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const setTableData = (myData: any) => {
    const arr = [...data, myData];
    const sortedData = [...arr].sort((a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day));
    console.log(sortedData);
    setData(sortedData);
  }


  const getAllStudentModal = async () => {
    setLoading(true);
    try {
      if (classId) {
        const res = await fetchStudent({ classId: classId });
        setStudent(res?.data?.students);
        setLoading(false);
      }else{
        const res = await fetchStudent();
        setStudent(res?.data?.students);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }finally{
      setLoading(false);
    }
  };



  const fetchPlannerData = async () => {
    // const res = await 
  }

  // useEffect(() => {
  //   (async () => {
  //     await getPlanner();
  //   })()
  // }, []);


  useEffect(() => {
    (async () => {
      const res = await getPlannerClasses();
      setClasses(res?.data.classes);
      // if (res?.data.classes.length > 0) {
      //   setClassId(res?.data.classes[0].classId);
      // }
    })();
  }, []);

  useEffect(() => {
    getAllStudentModal();
  }, [classId]);


  return checkRoutePermissions("studentPlanner") ? (
    <>
      <div className='mainDiv responsive'>
        <GHeader heading="Student Planner" />
        <div className='selct_plan_main'>
          <Select className='selct_plan' onChange={(e)=>{setClassId(e)}} placeholder="Select a class">
            {
              classes && classes.length > 0 && classes.map((item: any) => {
                return (
                  <Select.Option value={item.classId}>{item.className}</Select.Option>
                )
              })
            }
          </Select>
        </div>
        <div className='planner_top'>
          <p>Students List</p>
        </div>
        {
          loading?
          <Flex
          align="center"
          justify="center"
          style={{ height: "50vh" }}
          gap="middle"
        >
          <Spin size="large" />
        </Flex>:
                <div className='table_container' style={{ width: "100%" }}>
                <Table
                  className='custom_table_design'
                  columns={columns}
                  dataSource={student}
                  loading={loading}
                  pagination={false}
                  scroll={{x:600}}
                />
              </div>
        }

      </div>
    </>
  ) : (
    <NotAccess />
  );
};

export default StudentPlanner;
