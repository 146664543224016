import React, { useState, useEffect } from "react";
import { Drawer, Button } from "antd";
import { FaAlignLeft } from "react-icons/fa6";  // Third-party import
import { RxHamburgerMenu } from "react-icons/rx";

import SiderMenu from "./SiderMenu";           // Local import
import bill_icon from "../../assets/bill_icon.png"
import acedemichat from "../../assets/acedemic-hat.png"
import { GrNotification } from "react-icons/gr";


const SiderDrawer = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => { setVisible(true); }; // Add curly braces
  const onClose = () => { setVisible(false); };   // Add curly braces

  return (
    <>
      <div className="m-header">
        {/* <Header /> */}
        <Button onClick={showDrawer} className="" style={{ color: 'black' }}>
          <RxHamburgerMenu
            style={{
              color: "var(--white)",
              opacity: 0.9,
              fontSize: "30px"
            }}
          />
        </Button>
        <div className="header_text">
          <img src={acedemichat} alt="" />
          Progress Tracker
        </div>
        <div className="notificatioIcon-parent">
          <span className="notificationIconTick"></span>
          <GrNotification style={{ fontSize: "30px", color:"#777777"  }} />
        </div>

        {/* <img src={bill_icon} alt="" className="bill_icon_header" /> */}
      </div>
      <Drawer
        // width={300}
        className="mobile-menu-drawer"
        placement="left"
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <SiderMenu visible={visible} setVisible={setVisible} />
      </Drawer>
    </>
  );
};

export default SiderDrawer;

