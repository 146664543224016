
import "../../styles/pages/assesments.css";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Row, Spin, Table, Flex } from "antd";
import GHeader from "../../components/GeneralHeader/GHeader";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { useAppSelector } from "../../store/hooks";
import { CiRead } from "react-icons/ci";
import { getAllExams } from "../../store/services/assesment";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { render } from "react-dom";
import { title } from "process";
import Item from "antd/es/list/Item";
import CustomPagination from "../../components/Pagination/CustomPagination";
const Assessment = () => {
  const navigate = useNavigate();
  const [exam, setExam] = useState<any>([]);
  const [counts, setCounts] = useState(0);
  const [load, setLoad] = useState(false);
  const [pages, setPages] = useState(1);

  const { examList } = useAppSelector(state => state.exam);


  const columns = [
    {
      title: "Class Name",
      dataIndex: "className",
      render: (text: string) => {
        return (
          <p style={{ marginLeft: "20px" }}>{text}</p>
        )
      }
    },
    {
      title: "Assign Teacher",
      dataIndex: "firstName",
      render: (text: any, record: any) => {
        return (
          <p>{record.firstName}{"  "}{record.lastName}</p>
        )
      }
    },
    {
      title: "Details",
      dataIndex: "id",
      render: (text: any) => {
        return (
          <div style={{ marginLeft: "20px" }}>
            <Link to={`/examDetails/${text}`}>{<CiRead color="black" fontSize={20} />}</Link>
          </div>
        )
      }
    }
  ]


  const createClass = () => {
    navigate("/create-assesment");
  };

  const fetchExam = async () => {
    setLoad(true);
    try {
      const res = await getAllExams({ page: pages, limit: 10 });
      setCounts(res.count);
      setLoad(false);
    } catch (error: any) {
      setLoad(false);
      console.log("error", error.message);
    }
  }
  const setCurrentPage = (e: any) => {
    setPages(e);
  }

  useEffect(() => {
    fetchExam();
  }, [pages]);

  console.log("pages", pages);

  return checkRoutePermissions("assessmentsPage") ? (
    <main
      style={{
        textAlign: "center",
      }}
    >
      <div className="mainDiv responsive">
        <GHeader
          heading="Assessments"
          onClick={createClass}
          buttonText="Create an Exam"
        />
        {
          load ?
            <Flex
              align="center"
              justify="center"
              style={{ height: "50vh" }}
              gap="middle"
            >
              <Spin size="large" />
            </Flex> :
            <>
              <Row
                justify={{ xs: "center" }}
                style={{
                  padding: "20px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  height: "60vh"
                }}
              >
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <div>
              <img src={assessmentImage} alt="Upload assesment" />
              <h4>You have no exams here.</h4>
              <h4 className="create-exam-title">Create an exam.</h4>
              <Button
                onClick={() => navigate("/create-assesment")}
                icon={<FaPlus />}
                className="create-exam-btn"
              >
                Create an Exam
              </Button>
            </div>
          </div> */}

                <div className="table_container" style={{ width: "100%" }}>
                  <Table
                    className="custom_table_design"
                    scroll={{ x: 600 }}
                    dataSource={examList}
                    columns={columns}
                    pagination={false}
                  />
                </div>
              </Row>
              <div>
                <CustomPagination total={counts} currentPage={pages} setCurrentPage={setCurrentPage} />
              </div>
            </>
        }

      </div>
    </main>
  ) : (
    <NotAccess />
  );
};

export default Assessment;




