import React, { useEffect, useState } from "react";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { AiOutlineHome } from "react-icons/ai";
import "../../styles/pages/studentReport.css";
import { Avatar, Select, Spin } from "antd";
import avatarStd from "../../assets/avatar_student.png";
import ReactSpeedometer from "react-d3-speedometer";
import SpeedoMeter from "../../components/speedoMeter/SpeedoMeter";
import SubjectLevel from "../../components/subjectLevel/SubjectLevel";
import TargetToReach from "../../components/targetToReach/TargetToReach";
import HistoricalLevel from "../../components/HistoricalLevel/HistoricalLevel";
import StudentProfile from "../../components/studentProfile/StudentProfile";
import { useLocation } from "react-router-dom";
import { getStudentReport } from "../../store/services/report";
import { useAppSelector } from "../../store/hooks";
import GeneratorCard from "../../components/Modals/generatorCard/generatorCard";
import moment from "moment";
import { getStudentSubjects } from "../../store/services/class";
import { getSubjectCurrentLevel } from "../../store/services/subject";
import { getLevelProgress } from "../../store/services/learning";

const StudentReport = () => {
  const location = useLocation();
  const reportId = location?.state?.reportId;
  const { user } = useAppSelector((state) => state?.auth);
  const [loading, setLoading] = useState<boolean>(true);
  const [reportInfo, setReportInfo] = useState<any>(null);
  const [subjects, setSubjects] = useState<any>(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedSubjectLevel, setSelectedSubjectLevel] = useState<any>(null);


  useEffect(() => {
    if (reportId) {
      getReport(reportId);
    }
  }, [reportId]);

  useEffect(()=>{
    if(selectedSubject){
      getSubjectLevel(selectedSubject);
    }
  },[selectedSubject, reportInfo])

  const getReport = async (id: string | number) => {
    setLoading(true);
    const res = await getStudentReport(id);
    if (res) {
      console.log("res", res);
      setReportInfo(res?.data);
      // getSubjects();
      setLoading(false);
    }
  };

  const getSubjects = async ()=>{
    const res = await getStudentSubjects();
    if(res){
      console.log("student subjects res",res);
      setSubjects(res?.subjects);
      setSelectedSubject(res?.subjects[0]?.subjectId);
      getSubjectLevel(res?.subjects[0]?.subjectId);
    }
  }

const getSubjectLevel = async (subjectId: number | string)=>{
  if(reportInfo?.report?.classId){
  const res = await getLevelProgress(Number(reportInfo?.report?.classId), Number(subjectId));
  if(res){
    console.log("subject level res", res);
    setSelectedSubjectLevel(res?.data)
  }
}
}

  return checkRoutePermissions("StudentReport") ? (
    <>
    {
      loading? 
      <div className="report_generate_spin">
      <Spin style={{ fontSize: 40 }} />
      </div>
      :
      <div className="mainDiv responsive">
      <div className="std_reports_main">
        <AiOutlineHome color="#26252A" fontSize={"23px"} />
        <div className="std_reports_text">Reports</div>
        {/* <div className="std_reports_head_btn">Jan 6, 2024 - Jan 26, 2024</div> */}
        <div className="std_reports_head_btn">{moment(reportInfo?.report?.endDate)?.format("MMM DD, YYYY")}</div>
      </div>
      <div className="report_std_head">
        <div>
          <div className="report_std_head_text">{reportInfo?.report?.title}</div>
          <div className="report_std_head_text_normal">
            Review and manage the schools data.
          </div>
        </div>
        <div className="select_btn_student_parent">
          <Select
            placeholder="Previous Submitted Reports"
            className="select_btn_student"
          >
            <Select.Option value="1">Sample1</Select.Option>
            <Select.Option value="2">Sample2</Select.Option>
            <Select.Option value="3">Sample3</Select.Option>
          </Select>
        </div>
      </div>
      <StudentProfile
        image={reportInfo?.report?.imageUrl}
        name={`${reportInfo?.report?.firstName} ${reportInfo?.report?.lastName}`}
        classIndex={reportInfo?.report?.classIndex}
      />
      <div className="std_speedo_head">
        <div className="std_speedo_head_child ">
          <div className="report_level">Level</div>
          <div className="std_speedo_head_child1 std_speedo_head_childs "
          //  style={{user?.role == "student"?width:"100%" :""}}
           >
            {/* <p>Level at the time of report submission: - {(Number(reportInfo?.currentLevel?.progress) * 100).toFixed(0)}</p> */}
            {reportInfo && (
              <SpeedoMeter
                data={reportInfo?.currentLevel}
                value={Number(
                  (Number(reportInfo?.currentLevel?.progress) * 100).toFixed(0)
                )}
                maxValue={100}
              />
            )}
          </div>
        </div>
        {user?.role == "student" && <div className="std_speedo_head_child ">
          <div className="report_level">
            Current level at the time of submission
          </div>
          <div className=" std_speedo_head_childs ">
            <div className="std_reports_btn_parent">
              {subjects?.length > 0 &&
                subjects?.map((item: any) => {
                  return (
                    <div
                      onClick={(e) => {
                        setSelectedSubject(item.subjectId);
                      }}
                      className={`std_reports_btn ${
                        item.subjectId === selectedSubject && "std_reports_btn_active"
                      }`}
                    >
                      {item?.subjectName}
                    </div>
                  );
                })}
            </div>
            <div className="std_speedo_head_child1">
              {selectedSubjectLevel && <div className="leaning_card" style={{ width: "100%" }}>
                <SubjectLevel
                  percent={Number((Number(selectedSubjectLevel?.progress) * 100).toFixed(0))}
                  level="Current level"
                  link="#"
                  btnData="10% vs last month"
                  btn={true}
                  currentLevel={selectedSubjectLevel?.currentLevel?.levelName}
                />
              </div>}
            </div>
          </div>
        </div>}

      </div>
      {/* //////// Target to React  ///////// */}
      <div className="table_container learning_tab">
        <TargetToReach
        report={true}
          loading={loading}
          tableData={reportInfo?.pendingTargets?.map((item: any) => {
            return {
              name: reportInfo?.currentLevel?.level?.levelName,
              curriculum: item?.lessonName,
            };
          })}
        />
      </div>

      {/* //////////////// Historical Level */}
      {/* <div className="tab_learning_header">
                <HistoricalLevel />
            </div> */}

      <div>
        {reportInfo?.report?.effort && <GeneratorCard
          name="Effort /focus"
          view={true}
          value={reportInfo?.report?.effort}
        />}
        {reportInfo?.report?.attendance && <GeneratorCard
          name="Attendance"
          view={true}
          value={reportInfo?.report?.attendance}
        />}
        {reportInfo?.report?.punctuality && <GeneratorCard
          name="Punctuality"
          view={true}
          value={reportInfo?.report?.punctuality}
        />}
        {reportInfo?.report?.uniform && <GeneratorCard
          name="Uniform"
          view={true}
          value={reportInfo?.report?.uniform}
          />}
        {reportInfo?.report?.planner && <GeneratorCard
          name="Planner"
          view={true}
          value={reportInfo?.report?.planner}
        />}
      </div>
    </div>
    }
    
    </>
  ) : (
    <>
      <NotAccess />
    </>
  );
};

export default StudentReport;
