import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Modal,
  Input,
  Form,
  theme,
  notification,
  Spin,
  Tooltip,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  createLevelapi,
  createModuleapi,
  createTarget,
  getLearningapi,
  updateLevelApi,
  updateModuleApi,
} from "../../store/services/curriculum";

import "../Curriculum/Curriculum.css";
import { IoMdAdd } from "react-icons/io";
import clock from "../../assets/Clock.png";
import { GoPlus } from "react-icons/go";
import TargetModel from "../Modals/targetModal/targetModals";
import { IoIosArrowRoundBack } from "react-icons/io";
import { LuPlus } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../../pages/NotAccess";
import { FiEdit2 } from "react-icons/fi";

const { Panel } = Collapse;

interface TargetFormValues {
  target: string;
}
interface CurriculumDetailsProps {
  cId: any;
}

const CurriculumDetails: React.FC<CurriculumDetailsProps> = ({ cId }) => {
  const [isLevelModalVisible, setIsLevelModalVisible] = useState(false);
  const [isModuleModalVisible, setIsModuleModalVisible] = useState(false);
  const [isTargetModalVisible, setIsTargetModalVisible] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [currentModule, setCurrentModule] = useState<string>("");
  const [currentLevel, setCurrentLevel] = useState<string>("");
  const [levleValue, setLevelValue] = useState("");
  const [load, setLoad] = useState(false);
  const [currentLevelId, setCurrentLevelId] = useState<number>();
  const [levels, setLevels] = useState<any>(null);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<string>("main");
  const [hoveredPanel, setHoveredPanel] = useState<number | null>(null);
  const [levelValues, setLevelValues] = useState("");
  const [isEdit , setIsEdit] =useState(false)

  const { token } = theme.useToken();
  const subjectId = cId;
  console.log("🚀 ~ subjectId:", subjectId);
  const navigate = useNavigate();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
    color: "blue",
  };

  const handleAddTarget = (module: string, level: string) => {
    setCurrentLevel(level);
    setCurrentModule(module);
    setIsModalOpens(true);
    setIsEdit(false)
  };

  const handleAddModule = (levelId: number) => {
    setCurrentLevelId(levelId);
    setIsModuleModalVisible(true);
    setIsEdit(false)
  };
  const handleEditModule = (module: any) => {
    setCurrentLevelId(module?.moduleId);
    setIsModuleModalVisible(true);
    setIsEdit(true)
     form.setFieldValue("module",module?.name)
  };
  const handleEditLevel = (level:any) => {
    setCurrentLevelId(level.levelId);
    setIsLevelModalVisible(true);
   setIsEdit(true)
   setLevelValues(level?.level)
  };


  const getData = async () => {
    setIsLoading("main");

    const idAsNumber = parseInt(subjectId ?? "");
    const response = await getLearningapi({ subjectId: idAsNumber });
    if (response) {
      const newLevels = response.newLevelList.map((newLevel: any) => {
        const modules = newLevel.modules.map((module: any) => ({
          name: module.moduleName,
          targets: module.lessonsList.map((lesson: any) => lesson),
          moduleId: module.moduleId,
        }));

        const { levelId } = newLevel;
        setCurrentLevelId(newLevel.levelId);
        return {
          level: newLevel.levelName,
          modules: modules,
          levelId: levelId,
        };
      });
      setLevels(newLevels);
    }

    setIsLoading("");
  };

  useEffect(() => {
    getData();
  }, [subjectId]);

  const handleAddLevel = async (values: any, subjectId: string | undefined) => {
    try {
      setLoad(true);
     
      setIsLoading("level");
     
      let res;
      if (isEdit) {
        const updateLevels = {
          levelName: values, 
          levelId: currentLevelId,
        };
        res = await updateLevelApi(updateLevels);
      }else{
        const idAsNumber = subjectId ? parseInt(subjectId) : undefined;
        const payload:any = { levelName: values, subjectId: idAsNumber };
        res = await createLevelapi(payload);
      }

      //@ts-ignore

      if (res) {
        form.resetFields();
        setIsLevelModalVisible(false);
       setLoad(false);
        return res;
      } else {
        setIsLoading("");
        setLoad(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoad(false);
    }
    // setIsLoading("");
  };

  const handleModuleModalOk = async (values: any) => {
    const moduleData = {
      moduleName: values.module,
      levelId: currentLevelId,
    };
  
    setIsLoading("module");
  
    try {
      let res;
  
      if (isEdit) {
        const updateLevels = {
          moduleName: values.module,
          moduleId: currentLevelId,
        };
        res = await updateModuleApi(updateLevels);
    } else {
        res = await createModuleapi(moduleData);
      }
  
      if (res) {
        form.setFieldValue("module", undefined);
        setIsModuleModalVisible(false);
        await getData();
      }
    } catch (error) {
      console.error("Error updating or creating module:", error);
    } finally {
      setIsLoading(""); // Reset loading state
    }
  };
  

  const handleTargetModalOk = async (values: TargetFormValues) => {
    const obj = {
      lessonName: values?.target,
      moduleId: currentModule,
    };

    setIsLoading("target");
    const res = await createTarget(obj);
    if (res) {
      setIsTargetModalVisible(false);
      await getData();
    }
    // setIsLoading("");
  };

  const handleTargetClick = (target: string) => {
    navigate(`/lessondetails/${target}`);
  };

  const sendData = async () => {
    try {
      const levelToSend = levelValues;
      await handleAddLevel(levelToSend, subjectId);
      getData();
      setIsLevelModalVisible(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const addLevel = () => {
    setIsLevelModalVisible(true);
    setLevelValues(`level-${levels?.length + 1}`);
  };

  return checkRoutePermissions("curriculumDetails") ? (
    <div className="_curriculumDetailsContainer">
      <div className="maindivdetails">
        <div className="_heading">
          {/* <h2>
            {" "}
            <IoIosArrowRoundBack
              style={{ marginBottom: "-7px", cursor: "pointer" }}
              size={35}
              onClick={() => navigate(-1)}
            />{" "}
            Curriculum Details
          </h2> */}
        </div>

        <TargetModel
          isModalOpens={isModalOpens}
          currentModule={currentModule}
          setIsModalOpens={setIsModalOpens}
          getData={getData}
        />

        <div className="levelbtndiv">
          {/* <Button className="black-button" onClick={() => navigate(-1)}>
            <IoIosArrowRoundBack style={{ marginBottom: "-7px" }} size={25} />
            &nbsp;Back
          </Button> */}
          {levels && (
            <Button className="black-button" onClick={() => addLevel()}>
              <LuPlus style={{ marginBottom: "-5px" }} size={20} />
              &nbsp;Add a level
            </Button>
          )}
          <div></div>
        </div>

        {isLoading === "main" ? (
          <div className="_isLoading">
            <Spin />
          </div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <IoIosArrowDown
                  size={20}
                  className={`arrow-icon ${isActive ? "rotate" : ""} ${
                    hoveredPanel ? "hovered" : ""
                  }`}
                />
              )}
              style={{
                background: token.colorBgContainer,
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
              accordion
              className="_levelCollapse"
            >
              {levels && levels.length > 0 ? (
                levels?.map((level: any, index: number) => (
                  <Panel
                    header={level?.level}
                    key={index + 1}
                    className="collapsepanel"
                    extra={
                      <>
                        <Tooltip placement="bottom" title={"Add Module"}>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddModule(level.levelId);
                            }}
                            className="black-button"
                            style={{
                              borderRadius: "8px",
                              height: "40px",
                              width: "40px",
                            }}
                            icon={
                              <GoPlus size={25} style={{ marginTop: "2px" }} />
                            }
                          />
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Edit Level"}>
                        <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditLevel(level);
                             
                            }}
                            className="black-button"
                            style={{
                              borderRadius: "8px",
                              height: "40px",
                              width: "40px",
                            }}
                            icon={
                              <FiEdit2 size={22} style={{ marginTop: "2px" }} />
                            }
                          />
                        </Tooltip>
                      </>
                    }
                  >
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIcon={({ isActive }) => (
                        <IoIosArrowDown
                          size={20}
                          className={`arrow-icon ${isActive ? "rotate" : ""} ${
                            hoveredPanel ? "hovered" : ""
                          }`}
                        />
                      )}
                      style={{ background: token.colorBgContainer }}
                      accordion
                      className="_innerCollapse"
                    >
                      {level?.modules?.map((module: any) => (
                        <Panel
                          key={module?.name}
                          header={
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 7,
                              }}
                            >
                              {module?.name}
                              <div className="moduledurationclock">
                                <img src={clock} alt="" className="imgclock" />
                                <p>
                                  Module Duration: {module?.targets?.length}{" "}
                                  lessons
                                </p>
                              </div>
                            </p>
                          }
                          style={panelStyle}
                          className="innerpanel"
                          extra={
                            <>
                            
                            <Tooltip placement="bottom" title={"Add Target"}>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddTarget(
                                    module?.moduleId,
                                    level?.levelId
                                  );
                                }}
                                className="black-button"
                                style={{
                                  borderRadius: "8px",
                                  height: "40px",
                                  width: "40px",
                                }}
                                icon={
                                  <GoPlus
                                    size={25}
                                    style={{ marginBottom: "-2px" }}
                                  />
                                }
                              />
                              
                            </Tooltip>
                            <Tooltip placement="bottom" title={"Edit Modal"}>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                 
                                  handleEditModule( 
                                    module,    
                                  )
                                }}
                                className="black-button"
                                style={{
                                  borderRadius: "8px",
                                  height: "40px",
                                  width: "40px",
                                }}
                                icon={
                                  <FiEdit2
                                    size={22}
                                    style={{ marginTop: "2px" }}
                                  />
                                }
                              />
                            </Tooltip>
                            </>
                          }
                        >
                          <ul>
                            {module?.targets?.map((lesson: any, i: number) => {
                              return (
                                <li
                                  key={i}
                                  onClick={() =>
                                    handleTargetClick(lesson?.lessonId)
                                  }
                                  style={{ cursor: "pointer", color: "black" }}
                                >
                                  {lesson?.lessonName}
                                </li>
                              );
                            })}
                          </ul>
                        </Panel>
                      ))}
                    </Collapse>
                  </Panel>
                ))
              ) : (
                <div className="module_curriculum">No Data Found</div>
              )}
            </Collapse>
          </div>
        )}

        <Modal
          title="Add Target"
          open={isTargetModalVisible}
          onCancel={() => setIsTargetModalVisible(false)}
          footer={null}
          className="custom-modal"
          centered
        >
          <Form onFinish={handleTargetModalOk}>
            <Form.Item
              name="target"
              label="Target"
              rules={[{ required: true, message: "Please input the target!" }]}
            >
              <Input />
            </Form.Item>
            <Button
              loading={isLoading === "target"}
              disabled={isLoading === "target"}
              htmlType="submit"
              style={{
                backgroundColor: "var(--secondary-color)",
                color: "white",
              }}
            >
              Add Target
            </Button>
          </Form>
        </Modal>

        {levels && (
          <Modal
            title={<span className="levelTitle">{isEdit ? "Edit Level!!!" :'Add Level!!!'}</span>}
            open={isLevelModalVisible}
            onCancel={() => setIsLevelModalVisible(false)}
            footer={null}
            className="custom-modal"
            centered
          >
            {/* <Form
            layout={"vertical"}
            onFinish={(values) => handleAddLevel(values, subjectId)}
          >
            <Form.Item
              name="levelName"
              label="Level"
              className="level"
              rules={[
                { required: true, message: "Please input the level name!" },
              ]}
            >
              <Input placeholder="Add level" />
            </Form.Item>
            <div className="btn-parent">
              <Button
                onClick={() => setIsLevelModalVisible(false)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                loading={isLoading === "level"}
                disabled={isLoading === "level"}
                htmlType="submit"
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                }}
              >
                Add Level
              </Button>
            </div>
          </Form> */}
            <label className="label_curriculum">
              {" "}
              <span style={{ color: "red" }}>*</span> Level
            </label>
            <input
              value={levelValues}
              className="curriDetails"
              type="text"
              placeholder="Add Level"
              onChange={(e) => {
                setLevelValues(e.target.value);
              }}
            />
            <div className="btn-parent">
              <Button
                onClick={() => setIsLevelModalVisible(false)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                loading={load}
                disabled={load}
                // htmlType="submit"
                onClick={sendData}
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                }}
              >
               {isEdit ? " Edit Level" : " Add Level"}
              </Button>
            </div>
          </Modal>
        )}

        <Modal
          title={<span className="levelTitle">{isEdit ? 'Edit Module' : 'Add Module'}</span>}
          open={isModuleModalVisible}
          onCancel={() => {
            setIsModuleModalVisible(false);
            form.setFieldValue("module", undefined);
          }}
          footer={null}
          className="custom-modal"
          centered
          >
         
          <Form form={form} onFinish={handleModuleModalOk} layout="vertical">
            <Form.Item
              name="module"
              label= "Modal"
              rules={[
                { required: true, message: "Please input the module name!" },
              ]}
            >
              <Input placeholder="Add a Module" />
            </Form.Item>
            <div className="btn-parent">
              <Button
                onClick={() => {
                  setIsModuleModalVisible(false);
                  form.setFieldValue("module", undefined);
                }}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                loading={isLoading === "module"}
                disabled={isLoading === "module"}
                htmlType="submit"
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                }}
              >
               {isEdit ? 'Edit Module' :  "Add Module"}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default CurriculumDetails;
