import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { createTeacher, updateTeacher } from "../../../store/services/signup";

interface TeacherSignupModal {
  visible: boolean;
  onCancel: (value: boolean) => void;
  fetchTeachers?: () => void;
  isEdit?:boolean;
  editable?:any
}
interface SignupFormValues {
  salutation: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  className: string;
  remember?: string;
  image: { file: File };
}

const { Option } = Select;

const salutationOptions = ["Male", "Female"];
function TeacherSignupModal({
  visible,
  onCancel,
  fetchTeachers,
  isEdit,
  editable,
}: TeacherSignupModal) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEdit && editable) {
      form.setFieldsValue({
        salutation:editable.salutation,
        firstname: editable?.firstName || "",
        lastname: editable?.LastName || "",
        email: editable?.email || "",
        phone: editable?.phoneNumber === "undefined" ? "" : editable?.phoneNumber || "",
      });
    } else {
      form.resetFields(); 
    }
  }, [isEdit, editable, form]);
  

  const onFinish = async (values: SignupFormValues) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("salutation", values.salutation);
      formData.append("firstName", values.firstname);
      formData.append("lastName", values.lastname);
      formData.append("email", values.email);
      formData.append("password", values.password || ""); 
      values?.phone && formData.append("phoneNumber", values.phone);
      values?.image?.file && formData.append("pictures", values.image.file);
  
      if (isEdit && editable?.id) {
        formData.append("id", editable?.id); 
      }
  
      
      if (!isEdit) {
        const token = localStorage.getItem("token");
        if (token) {
          formData.append("token", token);
        }
      }
  
     
      // Use the correct API
      const res = isEdit
        ? await updateTeacher(formData)
        : await createTeacher(formData);
  
   
      fetchTeachers && fetchTeachers();
      form.resetFields();
      onCancel(false);
    } catch (error) {
      console.error("Failed to submit student data:", error);
    }
    setLoading(false);
  };



  return (
    <Modal open={visible} onCancel={() => onCancel(false)} footer={null}>
      <Row>
        <Col span={24}>
          <div className="modalheading">
            {isEdit ? <h1>Edit Teacher</h1> :<h1>Add Teacher</h1>}
            
          </div>
          <p>Please enter your details.</p>
          <br />
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="salutation"
              label="Gender"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <Select
                style={{ width: "100%", borderRadius: "8px" }}
                dropdownStyle={{
                  backgroundColor: "white",
                  border: "1px solid #d9d9d9",
                }}
              >
                {salutationOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <input className="input" placeholder="Enter Your First Name" />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <input className="input" placeholder="Enter Your Last Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <input className="input" placeholder="Enter your Email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={
                isEdit
                  ? [] 
                  : [{ required: true, message: "Please enter your password" }] 
              }
            >
              <input
                type="password"
                className="input"
                placeholder={isEdit ? "Enter new password (optional)" : "Enter your Password"}
              />
            </Form.Item>
            <Form.Item name="phone" label="Phone Number">
              <input className="input" placeholder="Enter your Phone Number" />
            </Form.Item>

            <Form.Item name="image" label="Upload Image">
              <Upload beforeUpload={() => false} listType="picture">
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Space>
              <Button className="cancelbtn" onClick={() => onCancel(false)}>
                Cancel
              </Button>
              <Button htmlType="submit" className="addbtn" loading={loading}>
                Add
              </Button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default TeacherSignupModal;
