import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import store from "..";
import {
  setStudentPerformance,
  setUpcomingClasses,
  setUpcomingLoading,
} from "../slices/dashboardSlice/teacherDashboardSlice";

export const getStudentPerformance = async () => {
  // console.log("payload >>" , payload);
  
  try {
    // const response = await privateAPI.post("/curriculum/create", payload);
    // console.log("API response >>" , response);
    // if (response) {
    store.dispatch(
      setStudentPerformance([
        {
          name: "Rachel Greene",
          type: "Student",
          class: "Class 5",
          className: "Tajweed",
          target: "28",
          percent: 90,
        },
        {
          name: "Emily Johnson",
          type: "Student",
          class: "Class 1",
          className: "Practical",
          target: "21",
          percent: 40,
        },
        {
          name: "Tom Lee",
          type: "Student",
          class: "Class 2",
          className: "Duas",
          target: "21",
          percent: 40,
        },
        {
          name: "Tom Lee",
          type: "Student",
          class: "Class 2",
          className: "Duas",
          target: "21",
          percent: 40,
        },
      ])
    );
    // }
    // return response;
    return true;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getUpcomingClasses = async () => {
  try {
    // const response = await privateAPI.post("/curriculum/create", payload);
    // if (response) {
    store.dispatch(setUpcomingLoading(true));
    store.dispatch(
      setUpcomingClasses(
        Array(5).fill({
          subjectName: "Tajweed",
          className: "Class 1",
          date: new Date().toISOString(),
          startTime: "01:00 PM",
          endTime: "02:00 PM",
        })
      )
    );
    // }
    // return response;
    return true;
  } catch (error: any) {
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  } finally {
    store.dispatch(setUpcomingLoading(false));
  }
};


export const getTopPerformer = async (data:{classId:number})=>{
  try {
    const res = await privateAPI.post("/student/search-student",data);
    if(res.status === 200){
      return res;
    }
  } catch (error) {
    console.log("error",error);
  }
}


export const getSchduleByTeacher = async(data:{teacherId:number,date:string})=>{
  try {
    const res = await privateAPI.post("/schedule/get-teacher-wise",data);
    if(res.status === 200){
      return res;
    }
  } catch (error) {
    console.log("error",error);
  }
}