import { privateAPI } from "../../config/constants";
import store from "..";
import { setExam } from "../slices/assesment/assesment";


export const getAllExams = async(data:{page:number,limit:number})=>{
    try {
        const res = await privateAPI.post("/exam/get-all",data);
        if(res.status === 200){
            store.dispatch(setExam(res.data.examList));
            return res.data;
        }
    } catch (error:any) {
        console.log("error exam",error.message);
    }
}