import React, { useEffect, useState } from "react";
import NotAccess from "../NotAccess";
import { checkRoutePermissions } from "../../utils/permission";
import "../../styles/pages/report.css";
import CardReport from "../../components/cardReport/CardReport";
import { useNavigate } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
import { useAppSelector } from "../../store/hooks";
import { getStudentReports } from "../../store/services/report";
import { Spin } from "antd";
import { report } from "process";

const Reports = () => {
  const { user } = useAppSelector(state => state.auth)
  const [reports, setReports] = useState<any>([])
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getReports = async () => {
      setLoading(true);
      const res = await getStudentReports();
      setLoading(false);
      if (res) {
        setReports(res?.data)
      }
    }

    getReports()
  }, [])

  return checkRoutePermissions("reportPage") ? (
    <div className="mainDiv responsive">
      <div className="report_head">
        <div>
          <h2 className="report_text">Reports</h2>
          <p className="report_normal_text">
            Review and manage the schools data.
          </p>
        </div>
        {user?.role === "teacher" && (
          <div
            className="generate_btn"
            onClick={() => {
              navigate("/report/generate");
            }}
          >
            <IoIosAdd className="report_add" />
            Generate Report
          </div>
        )}
      </div>
      {
        loading ? 
        <div className="spin_loading">
        <Spin />
        </div>
         :
          <>
            <div className="main_report_parent">
              <div className="main_report">
                <h1>This Year</h1>
              </div>

              <div className="card_reports">
                { reports?.current?.length>0 ? reports?.current?.map((item: any, index: number) => (
                  <CardReport key={index} data={item} />
                )):
                <div>No Data Found</div>
                }
              </div>
            </div>
            <div className="main_report_parent">
              <div className="main_report">
                <h1>Previous Year</h1>
              </div>
              <div className="card_reports">
                { reports?.previous?.length>0 ? reports?.previous?.map((item: any, index: number) => (
                  <CardReport key={index} data={item} />
                )):
                <div className="data_center">No Data found</div>
                }
              </div>
            </div>
          </>
      }

    </div>
  ) : (
    <NotAccess />
  );
};

export default Reports;
