import { Button, Flex, Input, Popconfirm, Select, Space, Spin, Table } from "antd";
import { CiSearch } from "react-icons/ci";
import { useEffect, useState } from "react";
import useDebounce from "../../Hook/useDebounce";
import { FiEdit2 } from "react-icons/fi";
import CustomPagination from "../../components/Pagination/CustomPagination";
import AdminCreateUpdate from "../Modals/AdminCreateUpdateModal";
import {
  addAdminArchive,
  getAllAdmins,
  unArchiveAdmin,
} from "../../store/services/admin";
import { FaEye } from "react-icons/fa";
import { IoEyeOffSharp } from "react-icons/io5";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";

const style: React.CSSProperties = {
  width: '300vw',
  height: '300vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const Admins: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [filterState, setFilterState] = useState({
    search: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdminModalVisible, setIsAdminModalVisible] =
    useState<boolean>(false);
  const [visibleAdminId, setVisibleAdminId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedAdminForEdit, setSelectedAdminForEdit] = useState({});

  const handlePasswordToggleVisibility = (record: any) => {
    setVisibleAdminId((prevId) => (prevId === record?.id ? null : record?.id));
  };

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);

  const col: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <img
              src={record?.imageUrl}
              alt="avatar"
              style={{ width: "40px", height: "40px", borderRadius: "50px" }}
            />
            <p style={{ fontSize: "14px" }}>
              {record.firstName} {record.lastName}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (_: any, record: any) => {
        return (
          <div>
            {visibleAdminId === record.id ? (
              <Space>
                <FaEye
                  style={{ fontSize: 20, cursor: "pointer", marginTop: 3 }}
                  onClick={() => handlePasswordToggleVisibility(record)}
                />
                <p>{record.normalPassword}</p>
              </Space>
            ) : (
              <IoEyeOffSharp
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() => handlePasswordToggleVisibility(record)}
              />
            )}
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
        >
          <FiEdit2
            onClick={() => {
              setEditMode(true);
              setSelectedAdminForEdit(record);
              setIsAdminModalVisible(true);
            }}
            style={{
              width: "20px",
              height: "20px",
              margin: "10px 0px",
              cursor: "pointer",
            }}
          />
          {record.status === "archive" ? (
            <MdOutlineUnarchive
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={(e) => {
                e?.stopPropagation();
                removeArchive(record);
              }}
            />
          ) : (
            <Popconfirm
            style={style}
            title="Archive Admins"
            description="Are you sure add to archive ?"
            onConfirm={(e) => {
              //  e?.stopPropagation();
              archive(record);
            }}
            //  onCancel={(e)=> e?.stopPropagation()}

            okText="Yes"
            cancelText="No"
          >
            <MdOutlineArchive
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={(e) => {
                e?.stopPropagation();
                console.log(record);
                
              }}
            />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const fetchAdmins = async (status?: string) => {
    try {
      setLoading(true);
      const payload = {
        page: currentPage,
        perPage: 10,
        search: filterState.search,
        status: status || "",
      };
      const res = await getAllAdmins(payload);
      setAllAdmins(res.records);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [currentPage, filterState]);

  const archive = async (record: any) => {
    const payload = {
      id: record.id,
    };
    await addAdminArchive(payload);
    fetchAdmins();
  };
  const removeArchive = async (record: any) => {
    const filteredAdmins: any = allAdmins.filter(
      (item: any) => item.id !== record.id
    );
    setAllAdmins(filteredAdmins);
    const payload = {
      id: record.id,
    };
    await unArchiveAdmin(payload);
  };

  const selectValue = async (e: any) => {
    setCurrentPage(1);
    await fetchAdmins(e);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="buttons">
          <Button onClick={() => setIsAdminModalVisible(true)}>Add</Button>
        </div>
      </div>
      <hr />
      <div className="fliters_container">
        <Input
          placeholder="Search..."
          onChange={(e) => debouncedFunction(e.target.value, "search")}
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
        />
        <Select
          defaultValue="All"
          style={{ width: 120, height: 45 }}
          onChange={(e: any) => selectValue(e)}
          options={[
            { value: "", label: "All" },
            { value: "archive", label: "Archive" },
          ]}
        />
      </div>
      {loading ? (
        <Flex
          align="center"
          justify="center"
          style={{ height: "50vh" }}
          gap="middle"
        >
          <Spin size="large" />
        </Flex>
      ) : (
        <div className="main_tab" style={{ marginTop: "10px" }}>
          <div className="table_container">
            <Table
              className="custom_table_design"
              scroll={{ x: 600 }}
              dataSource={allAdmins}
              columns={col}
              pagination={false}
            />
          </div>
          <CustomPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={allAdmins?.length}
          />
        </div>
      )}
      <AdminCreateUpdate
        visible={isAdminModalVisible}
        onCancel={() => setIsAdminModalVisible(false)}
        isEditMode={editMode}
        editObj={selectedAdminForEdit}
        fetchAdmins={fetchAdmins}
      />
    </div>
  );
};

export default Admins;
