import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Flex,
  Form,
  Input,
  Row,
  Spin,
  theme,
  Tooltip,
} from "antd";
import { CiSearch } from "react-icons/ci";
import { GoPlus } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsChevronExpand } from "react-icons/bs";
import BookImage from "../../assets/book.png";
import Book2Image from "../../assets/book_2.png";
import StarImage from "../../assets/star.png";
import StudentPerformanceCard from "../../components/Cards/StudentPerformanceCard";
import TeacherCard from "../../components/Cards/TeacherCard";
import { useNavigate } from "react-router-dom";
import { getAllTeachersList } from "../../store/services/teacher";
import TargetModelAdmin from "../../components/Modals/targetModal/targetModalAdmin";
import SearchableDropdown from "../../components/SearchableDropdown";
import { getLearnByTarget, getLearningapi } from "../../store/services/curriculum";
import { IoIosArrowDown } from "react-icons/io";
import { getAllSubject } from "../../store/services/subject";
import { getAllStudents } from "../../store/services/student";
import StudentSignupModal from "../../components/Modals/studentModal/StudentSignupModal";
import TeacherSignupModal from "../../components/Modals/Teachers/TeacherSignupModal";

interface Target {
  Model: string;
  Subject: string;
}
const topStudents = [
  {
    name: "Rachel Greene",
    type: "Student",
    class: "Class 5",
    className: "Tajweed",
    target: "28",
    percent: 90,
  },
  {
    name: "Emily Johnson",
    type: "Student",
    class: "Class 1",
    className: "Practical",
    target: "21",
    percent: 40,
  },
  {
    name: "Tom Lee",
    type: "Student",
    class: "Class 2",
    className: "Duas",
    target: "21",
    percent: 40,
  },
];

const panelStyle = {
  marginBottom: 24,
  background: "#ffffff",
  borderRadius: "8px",
  border: "none",
};
const panelStyle2 = {
  marginBottom: 24,
  background: "#ffffff",
  borderRadius: "8px",
  border: "none",
  fontSize: "10px",
  // color:"red"
};

const openCloseIcon = (isActive: any) => {
  return isActive ? (
    <MdKeyboardArrowDown style={{ width: "20px", height: "20px" }} />
  ) : (
    <BsChevronExpand style={{ width: "20px", height: "20px" }} />
  );
};

const AdminDashboard = () => {
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState([]);
  const [totalTeachers, setTotalTeachers] = useState<number>(0);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [currentModule, setCurrentModule] = useState<number | null>(null);
  const [activeItem, setActiveItem] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchValue, setFetchValue] = useState([]);
  const [levels, setLevels] = useState<any>([]);
  const [targets, setTarget] = useState<any>([]);
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [isAddTeacherModalVisible, setIsAddTeacherModalVisible] =
    useState<boolean>(false);
  const [isAddStudentModalVisible, setIsAddStudentModalVisible] =
    useState<boolean>(false);
  const [totalStudents, setTotalStudents] = useState<number>(0);
  const [currentLevelId, setCurrentLevelId] = useState<number>();


  const { token } = theme.useToken();

  const handleMOdal = () => {
    setIsModalOpens(true);
  };
  useEffect(()=>{
    sendData()
  },[])

  const sendData = async () => {
    const getSub = await getAllSubject(0,10,"","active");
    
    if (getSub) {
      setDefaultValue(getSub.subjectList[0].subjectName);
      const payload = {
        subjectId: Number(getSub.subjectList[0].subjectId),
      };
      
      const response = await getLearningapi(payload);
      setCurrentModule(getSub.subjectList[0].subjectId);
     
      if (response) {
        setIsLoading(true);
        const newLevels = response.newLevelList.map((newLevel: any) => {
        const modules = newLevel.modules.map((module: any) => ({
          name: module.moduleName,
          targets: module.lessonsList.map((lesson: any) => lesson),
          moduleId: module.moduleId,
        }));

        const { levelId } = newLevel;
        setCurrentLevelId(newLevel.levelId);
        return {
          level: newLevel.levelName,
          modules: modules,
          levelId: levelId,
        };
      });
      setLevels(newLevels);
      setTarget(newLevels)
    }
      setIsLoading(false);
    }
    setActiveItem(false);
  };

  const onChange = async (e: any) => {
    const findClass: any = fetchValue.find((item: any) => {
      return e === item.subjectId;
    });
    setDefaultValue(findClass.subjectName);
    setLevels([]);
    setIsLoading(true);
    const payload = {
      subjectId: findClass.subjectId,
    };
    setCurrentModule(findClass.subjectId);
    const response = await getLearningapi(payload);
    if (response) {
      setIsLoading(true);
      const newLevels = response.newLevelList.map((newLevel: any) => {
      const modules = newLevel.modules.map((module: any) => ({
        name: module.moduleName,
        targets: module.lessonsList.map((lesson: any) => lesson),
        moduleId: module.moduleId,
      }));

      const { levelId } = newLevel;
      setCurrentLevelId(newLevel.levelId);
      return {
        level: newLevel.levelName,
        modules: modules,
        levelId: levelId,
      };
    });
    setLevels(newLevels);
    setTarget(newLevels)
  }
    setIsLoading(false);
    setActiveItem(false);
  };

  const fetchTeachers = async () => {
    try {
      const payload = {
        page: 0,
        perPage: 10,
      };
      const res = await getAllTeachersList(payload);
      setTeacher(res?.records);
      setTotalTeachers(res?.count);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchStudents = async () => {
    try {
      const payload = {
        page: 1,
        perPage: 0,
      };
      const res = await getAllStudents(payload);
      setTotalStudents(res?.count);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getData = async () => {
    setLevels([]);
    setIsLoading(true);
    setActiveItem(true);
    const payload = {
      subjectId: Number(currentModule),
    };

    const response = await getLearningapi(payload);
    if (response) {
      setIsLoading(true);
      const newLevels = response.newLevelList.map((newLevel: any) => {
      const modules = newLevel.modules.map((module: any) => ({
        name: module.moduleName,
        targets: module.lessonsList.map((lesson: any) => lesson),
        moduleId: module.moduleId,
      }));

      const { levelId } = newLevel;
      setCurrentLevelId(newLevel.levelId);
      return {
        level: newLevel.levelName,
        modules: modules,
        levelId: levelId,
      };
    });
    setLevels(newLevels);
    setTarget(newLevels)
  }
    setIsLoading(false);
    setActiveItem(false);
  };

  useEffect(() => {
    fetchTeachers();
    fetchStudents();
    // setLevels([]);
    // setCurrentModule('')
    getData();
  }, [refreshKey]);

  const handleKey = () => {
    setRefreshKey((prevKey) => prevKey + 1); 
    setIsModalOpens(false);
  };



  return (
    <>
      <TeacherSignupModal
        visible={isAddTeacherModalVisible}
        onCancel={setIsAddTeacherModalVisible}
        fetchTeachers={fetchTeachers}
      />
      <StudentSignupModal
        visible={isAddStudentModalVisible}
        onCancel={setIsAddStudentModalVisible}
        fetchStudents={fetchStudents}
      />

      <div className="dashboard_container">
        <Row>
          <Col
            className="admin_dash_col"
            lg={{ order: 1, span: 18 }}
            sm={{ order: 2, span: 24 }}
          >
            <div className="left_column green-scrollbar">
              <div className="wrapper">
                <div className="header">
                  <div className="top">
                    <p className="title">Welcome back! 👋🏼</p>
                    <p className="desc">Review and manage the school data</p>
                  </div>

                  <div className="bottom">
                    <Input
                      size="large"
                      placeholder="Search..."
                      className="dashboard_search"
                      prefix={<CiSearch />}
                    />
                  </div>
                </div>

                {/* cards container */}
                <div className="dashboard_cards_container">
                  <div className="dashboard_card">
                    <img
                      src={BookImage}
                      alt="book_image"
                      className="book_image"
                    />
                    <img
                      src={StarImage}
                      alt="star_image"
                      className="star_image"
                    />
                    <div className="top">
                      <p className="label">Total Students Number</p>
                      <p className="value additionalValueClass">
                        {totalStudents}
                      </p>
                    </div>
                    <Button
                      className="card_add_btn"
                      onClick={() => setIsAddStudentModalVisible(true)}
                    >
                      <GoPlus /> Add
                    </Button>
                  </div>

                  <div className="dashboard_card dashboard_card_2">
                    <img
                      src={Book2Image}
                      alt="book_2_image"
                      className="book_image"
                    />
                    <img
                      src={StarImage}
                      alt="star_image"
                      className="star_image"
                    />
                    <div className="top">
                      <p className="label">Total Teachers Number</p>
                      <p className="value additionalValueClass">
                        {totalTeachers}
                      </p>
                    </div>
                    <Button
                      className="card_add_btn"
                      onClick={() => setIsAddTeacherModalVisible(true)}
                    >
                      <GoPlus />
                      Add
                    </Button>
                  </div>
                </div>

                {/* Performers */}
                <div className="performers_main_container">
                  <div className="performance_header">
                    <p className="label">Top Performers</p>
                    <p
                      className="view-all"
                      onClick={() => {
                        navigate("/dashboard/top-performers");
                      }}
                    >
                      View All
                    </p>
                  </div>
                  <div className="cards">
                    {topStudents?.map((item: any) => {
                      return (
                        <>
                          <StudentPerformanceCard
                            key={item?.name}
                            data={item}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="performers_main_container">
                  <div className="performance_header">
                    <p className="label">Teachers</p>
                    <p
                      className="view-all"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/dashboard/teachersreview");
                      }}
                    >
                      View All
                    </p>
                  </div>
                  <div className="cards">
                    {teacher?.slice(0, 11)?.map((item: any) => {
                      return <TeacherCard key={item?.name} data={item} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 2, span: 24 }}
            lg={{ order: 2, span: 6 }}
            sm={{ order: 1, span: 24 }}
          >
            <div className="right_column">
              <p className="column_title">Targets</p>
              <div className="targets_container green-scrollbar">
                <div style={{ marginBottom: "10px" }}>
                  <SearchableDropdown
                    onChange={onChange}
                    setFetchedOptions={setFetchValue}
                    url="/subject/get-all"
                    mode={false}
                    placeholder="Search by subject name"
                    columns={["subjectName"]}
                    type="subject"
                    defaultValue={defaultValue}
                  />
                </div>

                {isLoading ? (
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: "50vh" }}
                    gap="middle"
                  >
                    <Spin size="large" />
                  </Flex>
                ) : !currentModule ? (
                  <h1 style={{ textAlign: "center" }}>Select Target</h1>
                ) : (
                  <Collapse
                    className="custom_dashboard_collapse"
                    bordered={false}
                    expandIcon={({ isActive }) => openCloseIcon(isActive)}
                    style={{ background: "transparent" }}
                  >
                    {levels.length > 0 ? (
                      levels.map((level: any, index: number) => (
                        <Panel
                          header={level.level}
                          key={index}
                          style={panelStyle}
                        >
                          <Collapse
                            bordered={false}
                            expandIcon={({ isActive }) => (
                              <IoIosArrowDown
                                size={20}
                                className={`arrow-icon ${
                                  isActive ? "rotate" : ""
                                }`}
                              />
                            )}
                            style={{ background: token.colorBgContainer }}
                            accordion
                            className="_innerCollapse"
                          >
                            {level?.modules?.map((module: any, i: number) => (
                              <Panel
                              key={module?.name}
                              header={module?.name}
                              style={panelStyle2}
                              className="innerpanel"
                             
                            >
                              <ul style={{paddingLeft:'10px'}}>
                                {module?.targets?.map((lesson: any, i: number) => {
                                  return (
                                    <li
                                      key={i}
                                      style={{ cursor: "pointer", color: "black",fontSize:'14px' }}
                                    >
                                      {lesson?.lessonName}
                                    </li>
                                  );
                                })}
                              </ul>
                            </Panel>
                            ))}
                          </Collapse>
                        </Panel>
                      ))
                    ) : (
                      <p style={{ textAlign: "center", color: "gray" }}>
                        No lessons available
                      </p>
                    )}
                  </Collapse>
                )}
              </div>
              <Button
                className="add_target_btn"
                onClick={handleMOdal}
                disabled={activeItem}
              >
                <GoPlus /> Add Target
              </Button>

              <TargetModelAdmin
                isModalOpens={isModalOpens}
                currentModule=" "
                setIsModalOpens={setIsModalOpens}
                onAddTarget={targets}
                getData={handleKey}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminDashboard;
