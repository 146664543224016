import { Button } from "antd";
import moment from "moment";
import React from "react";

interface Props {
  data: any;
}
const UpcomingClassesCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="_UpcomingClassesCard">
      <div className="_upcomingHeader">
        <h2>{data?.subjectName}</h2>
        <Button>{data?.className}</Button>
      </div>
      <div className="_upcomingContent">
        <div className="_clockBox">
          <img src={require("../../assets/clock-black.png")} />
        </div>
        <div className="_scheduleBox">
          <h3>{moment(data?.date).format("dddd, MMM DD, YYYY")}</h3>
          <p>
            {data?.startTime} - {data?.endTime}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpcomingClassesCard;
