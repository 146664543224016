import { Progress } from "antd";
import React from "react";

interface Props {
  data: any;
}
const ProgressCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="_progressCard">
      <div className="_iconBox">
        <img src={require("../../assets/bag-pack-blue.png")} />
      </div>
      <div className="_detail">
        <h2>{data?.subjectName}</h2>
        <p>{data?.teacherName}</p>
      </div>
      <div className="_progressBar">
        <Progress percent={Number(data?.percent)} />
      </div>
    </div>
  );
};

export default ProgressCard;
