import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Form,
  Space,
  notification,
  Tooltip,
  Tag,
  Flex,
  Spin,
  message,
} from "antd";

import { LuPlus } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import "../styles/pages/monitoring.css";
import { UserOutlined } from "@ant-design/icons";
import SearchableDropdown from "../components/SearchableDropdown";
import Layout from "../components/layout";

import addstudent from "../assets/addstudent.png";

import CustomPagination from "../components/Pagination/CustomPagination";
import { render } from "react-dom";
import GHeader from "../components/GeneralHeader/GHeader";
import NotAccess from "./NotAccess";
import { checkRoutePermissions } from "../utils/permission";
import {
  addMonitoringstudent,
  deleteMonitoring,
  getAllMonitoring,
  getStudent,
  searchData,
} from "../store/services/monitoring";
import { fetchStudent } from "../store/services/PlannerModal";
import { privateAPI } from "../config/constants";
import { getAllClasses } from "../store/services/teacher";
import { useForm } from "antd/es/form/Form";
import { debounce, filter } from "lodash";
import { getSubjectListByClassDropdown } from "../store/services/subject";
import { getLevelBySubject } from "../store/services/class";

const dataSource = [
  {
    key: "1",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajwee",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "2",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "3",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "4",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "5",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "6",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "7",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "8",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "9",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "10",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "11",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "12",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "13",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "14",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "15",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "16",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "17",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "18",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
  {
    key: "19",

    name: "Ms. Rodriguez",
    lastname: "Rodriguez@gmail.com",
    subject: "Tajweed",
    class: "Class 1",
    atlevel: "Level 1",
    targetlevel: "Level 2",
  },
];

const Monitoring = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [studentId, setStudentId] = useState("");
  const [name, setName] = useState<string | null>(null);
  const [defaultVal, setDefaultVal] = useState("");
  const [val, setVal] = useState("");
  const [monitoringStudent, setMontoringStudent] = useState({});
  const [value, setValue] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [classes, setClasses] = useState([]);
  const [student, setStudent] = useState([]);
  const [fetchValue, setFetchValue] = useState([]);
  const [subjectValue, setSubjectValue] = useState(null);
  const [levelValue, setLevelValue] = useState(null);
  const [dropdownSubjects, setDropdownSubjects] = useState([]);
  const [dropdownLevels, setDropdownLevels] = useState<any>([]);
  const [selectedStudentForDelete, setSelectedStudentForDelete] = useState<{
    studentData: any;
    showConfirmationModal: boolean;
  }>({
    studentData: null,
    showConfirmationModal: false,
  });

  console.log("fetchValue", fetchValue);

  const [pages, setPages] = useState(1);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setStudentId("");
    fetchStudent("");
    setDefaultVal("");
    setName(null);
  };
  const [form] = Form.useForm();

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "22%",
      render: (text: any, record: any) => {
        return (
          <div className="tab_left">{`${record.salutation} ${record.firstName}`}</div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: "22%",
      render: (text: any) => {
        return <div className="tab_left">{text}</div>;
      },
    },
    {
      title: "Class",
      dataIndex: "className",
      key: "className",
      width: "22%",
      render: (text: any) => {
        return <div className="tabs_le">{text}</div>;
      },
    },
    {
      title: "Subjects",
      dataIndex: "subjectName",
      key: "subjectName",
      width: "18%",
      render: (text: any) => {
        return <div className="tabs_le">{text}</div>;
      },
    },
    {
      title: "Level",
      dataIndex: "levelName",
      key: "levelName",
      width: "22%",
      render: (text: any, record: any) => {
        return <div className="tabs_le">{text}</div>;
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: "22%",
      render: (per: any) => {
        return (
          <div>
            <Progress
              percent={Math.floor(per)}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_: any, record: any) => {
        return (
          <div
            className="tabs_left"
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {/* <FiEdit2
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            /> */}
            <IoTrashOutline
              onClick={() =>
                setSelectedStudentForDelete({
                  studentData: record,
                  showConfirmationModal: true,
                })
              }
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const getAllMonitoringData = async () => {
    try {
      setLoading(true);
      const res = await getAllMonitoring({ page: pages, perPage: 10 });
      setData(res?.monitoringList);
      setCount(res?.count);
      setLoading(false);
    } catch (error) {
      console.log("error getAllMonitoringData", error);
      setLoading(false);
    }
  };

  const deleteMonitoringData = async (id: number) => {
    try {
      setLoading(true);
      const res = await deleteMonitoring({ monitoringId: id });
      if (res) {
        getAllMonitoringData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const fetchStudent = async (search: string) => {
    try {
      const res = await getStudent({ username: search });
      setStudent(res?.data?.students);
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeValue = async () => {
    try {
      setLoading(true);
      const data = {
        searchTerm: searchTerm,
        page: pages,
        perPage: 10,
        classId: value,
        subjectId: subjectValue,
        levelId: levelValue,
      };

      const res = await getAllMonitoring(data);
      console.log(res)
      setData(res?.monitoringList);
      setCount(res?.count);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchClasses = async () => {
    try {
      const res = await getAllClasses();
      setClasses(res?.data.records);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (value: string, option: any) => {
    setName(option?.children[0] + " " + option?.children[2]);
    setStudentId(value);
  };

  const handleSearch = (value: string) => {
    fetchStudent(value);
  };

  useEffect(() => {
    getAllMonitoringData();
    fetchClasses();
  }, []);

  const submitData = async () => {
    setLoading(true);
    try {
      if (!monitoringStudent || Object.keys(monitoringStudent).length === 0) {
        setLoading(false);
        message.error("You have to select the student first");
        return;
      }
      const findData = data.find((item: any) => {
        //@ts-ignore
        return item.studentId === monitoringStudent.id;
      });
      if (findData) {
        setDefaultVal("");
        setLoading(false);
        setIsModalOpen(false);
        notification.error({ message: "You already added this student" });
        setStudentId("");
        return;
      }

      const submitData = {
        //@ts-ignore
        studentId: monitoringStudent.id,
      };
      const res = await addMonitoringstudent(submitData);
      if (res?.status === 200) {
        getAllMonitoringData();
        setDefaultVal("");
        form.resetFields();
        setMontoringStudent({});
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsModalOpen(false);
    } finally {
      setDefaultVal("");
      setName(null);
      form.resetFields();
    }
  };

  // const debouncedFetchByClass = useCallback(
  //   debounce(changeValue, 1000),
  //   [searchTerm]
  // );

  // useEffect(() => {
  //   if(searchTerm){
  //     debouncedFetchByClass();
  //     return () => {
  //       debouncedFetchByClass.cancel();
  //     };
  //   }else{
  //     getAllMonitoringData();
  //   }

  // }, [searchTerm, value, debouncedFetchByClass]);

  const debounceFetchByClass = useCallback(debounce(changeValue, 1000), [
    searchTerm,
    subjectValue,
    levelValue,
  ]);

  useEffect(() => {
    if (searchTerm || subjectValue) {
      debounceFetchByClass();
      return () => {
        debounceFetchByClass.cancel();
      };
    } else {
      changeValue();
    }
  }, [searchTerm, subjectValue, levelValue, debounceFetchByClass]);

  const onChange = (e: any) => {
    setDefaultVal(e);
    const filterData = fetchValue.filter((item: any) => {
      return item.id === e;
    });
    setMontoringStudent(filterData[0]);
  };

  const handleClassChange = async (e: any) => {
    setSubjectValue(null);
    setLevelValue(null);
    setDropdownLevels([]);
    setDropdownSubjects([]);
    setValue(e);
    const res = await getSubjectListByClassDropdown({ classId: e });
    setDropdownSubjects(res.subjects);
  };

  const handleSubjectChange = async (e: any) => {
    setLevelValue(null);
    setDropdownLevels([]);
    setSubjectValue(e);
    const res = await getLevelBySubject({ subjectId: e });
    setDropdownLevels([{ levelName: "All Levels", levelId: null }, ...res]);
    console.log(dropdownLevels);
  };

  return checkRoutePermissions("monitoringPage") ? (
    <>
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Add Student</h1>
          <div className="pdiv">
            {/* <p className="modoalp">Add a student to a monitor</p> */}
          </div>
        </div>
        <div className="form" style={{ width: "100%" }}>
          {/* <Select
            onChange={handleChange}
            showSearch
            style={{ width: "100%", marginBottom: "20px" }}
            placeholder="Search Student"
            onSearch={handleSearch}
            filterOption={false}
            value={name}
          >
            {student?.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.firstName} {item.lastName}
              </Select.Option>
            ))}
          </Select> */}
          <SearchableDropdown
            onChange={onChange}
            setFetchedOptions={setFetchValue}
            url="/student/get-all"
            mode={false}
            placeholder="Search by student name"
            defaultValue={defaultVal}
            columns={["firstName",'lastName']}
            type={"monitoring"}
          />
          <Space style={{ width: "100%" }}>
            <div className="student_modal_button">
              <Button onClick={handleCancel} className="cancelbtn">
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                onClick={submitData}
                htmlType="reset"
                className="addbtn"
              >
                Add
              </Button>
            </div>
          </Space>
        </div>
      </Modal>
      <Modal
        title="Delete Stuent"
        open={selectedStudentForDelete.showConfirmationModal}
        onOk={() => {
          setSelectedStudentForDelete({
            studentData: null,
            showConfirmationModal: false,
          });
          deleteMonitoringData(
            selectedStudentForDelete.studentData.monitoringId
          );
        }}
        onCancel={() =>
          setSelectedStudentForDelete({
            studentData: null,
            showConfirmationModal: false,
          })
        }
      >
        <p>Are you sure you want to delete this student?</p>
      </Modal>
      <div className="mainDiv responsive">
        <div style={{ height: "100%" }}>
          <div className="main_parent">
            <div>
              <GHeader
                heading="Monitoring"
                numberOfPeople={`${count ?? 0} People`}
                onClick={showModal}
                buttonText="Add a Student"
              />
              <div className="fliters_container">
                <Input
                  placeholder="Search..."
                  //@ts-ignore
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  prefix={
                    <CiSearch
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#667085",
                      }}
                    />
                  }
                  className="filter_search"
                />

                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Select a class"
                    value={value}
                    onChange={handleClassChange}
                    optionFilterProp="children"
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {classes?.length > 0 &&
                      classes.map((item: any) => {
                        return (
                          <Select.Option value={item.classId}>
                            {item.className}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Select a subject"
                    value={subjectValue}
                    disabled={dropdownSubjects.length === 0}
                    onChange={handleSubjectChange}
                    optionFilterProp="children"
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {dropdownSubjects?.length > 0 &&
                      dropdownSubjects.map((item: any) => {
                        return (
                          <Select.Option value={item.subjectId}>
                            {item.subjectName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Select a level"
                    disabled={dropdownLevels.length === 0}
                    value={levelValue}
                    onChange={(e) => {
                      setLevelValue(e);
                    }}
                    optionFilterProp="children"
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {dropdownLevels?.length > 0 &&
                      dropdownLevels.map((item: any) => {
                        return (
                          <Select.Option value={item.levelId}>
                            {item.levelName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="select_container">
                  <Button
                    className="btn_clear"
                    onClick={() => {
                      getAllMonitoringData();
                      setSearchTerm(null);
                      setValue(null);
                      setSubjectValue(null);
                      setLevelValue(null);
                      setDropdownLevels([]);
                      setDropdownSubjects([]);
                    }}
                  >
                    Clear
                  </Button>
                </div>
                {/* 
                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="All Subjects"
                    optionFilterProp="children"
                    onChange={onSelectChange}
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{ width: "20px", height: "20px", color: "#667085" }}
                      />
                    }
                    options={[
                      {
                        value: "1",
                        label: "Underperforming",
                      },
                      {
                        value: "2",
                        label: "Overperforming",
                      },
                    ]}
                  />
                </div> */}
                {/* 
                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Level 1"
                    optionFilterProp="children"
                    onChange={onSelectChange}
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{ width: "20px", height: "20px", color: "#667085" }}
                      />
                    }
                    options={[
                      {
                        value: "1",
                        label: "Level 1",
                      },
                      {
                        value: "2",
                        label: "Level 2",
                      },
                    ]}
                  />
                </div> */}
              </div>
            </div>
            {loading ? (
              <Flex
                align="center"
                justify="center"
                style={{ height: "50vh" }}
                gap="middle"
              >
                <Spin size="large" />
              </Flex>
            ) : (
              <>
                <div className="mian_tab" style={{ marginTop: "10px" }}>
                  <div className="table_container">
                    <Table
                      className="custom_table_design"
                      dataSource={data}
                      columns={columns}
                      scroll={{ x: 600 }}
                      loading={loading}
                      pagination={false}
                    />
                  </div>
                </div>
                <div className="main_pagination">
                  <CustomPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    total={count}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotAccess />
  );
};

export default Monitoring;
