import React, { useCallback, useEffect, useState } from "react";
import {
  Input,
  Select,
  Table,
  Space,
  Tooltip,
  Tag,
  Spin,
  Flex,
  Button,
  Popconfirm,
} from "antd";
import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { IoMdCreate } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import {
  deleteTeacher,
  getAllClasses,
  getAllTeachersList,
  getByClass,
} from "../../store/services/teacher";
import Avatar1 from "../../assets/moulviAvatar.jpeg";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useAppSelector } from "../../store/hooks";
import { teacherDetails } from "../../store/interfaces/Teacher/Teacher";
import { IoFileTraySharp } from "react-icons/io5";
import AssignSubjectToTeacherModal from "../../components/Modals/Teachers/AssignSubjectToTeacherModal";
import AssignClassToTeacherModal from "../../components/Modals/Teachers/AssignClassToTeacherModal";
import GHeader from "../../components/GeneralHeader/GHeader";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { debounce } from "lodash";
import TeacherSignupModal from "../../components/Modals/Teachers/TeacherSignupModal";
import { GoPlus } from "react-icons/go";

const TeacherNew = () => {
  const [pages, setPages] = useState(1);
  const [classes, setClasses] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [classId, setClassId] = useState<null | number>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]); // Track expanded rows
  const { allTeachers, loadingTeachers } = useAppSelector(
    (state) => state.teacher
  );
  const [isAddTeacherModalVisible, setIsAddTeacherModalVisible] =
    useState<boolean>(false);

  const fetchTeachers = async () => {
    try {
      setLoading(true);
      const payload = {
        page: pages,
        perPage: 10,
        search: "",
      };
      const res = await getAllTeachersList(payload);

      setCount(res.count);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchClasses = async () => {
    try {
      const res = await getAllClasses();
      setClasses(res?.data.records);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchByClass = async () => {
    setLoading(true);
    try {
      const payload = {
        page: 1,
        perPage: 30,
        classId: classId,
      };
      const payloads = {
        page: 1,
        perPage: 30,
        classId: classId,
        name: search,
      };
      const res = await getByClass(search ? payloads : payload);
      setCount(res?.data.total);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const teacherDelete = async (id: number) => {
    try {
      const res = await deleteTeacher(id);
      if (res) {
        fetchTeachers();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const debouncedFetchByClass = useCallback(debounce(fetchByClass, 1000), [
    search,
    classId,
  ]);

  useEffect(() => {
    if (classId || search) {
      debouncedFetchByClass();
      return () => {
        debouncedFetchByClass.cancel();
      };
    } else {
      fetchTeachers();
    }
  }, [search, classId, debouncedFetchByClass]);

  useEffect(() => {
    fetchClasses();
  }, [pages]);

  //  useEffect(() => {
  //     if (classId || search) {
  //       debouncedFetchByClass();
  //     }
  //   }, [classId, search, debouncedFetchByClass]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const col: any = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text: string, record: teacherDetails) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={record?.imageUrl !== "" ? record.imageUrl : Avatar1}
            alt="avatar"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          />
          <p style={{ fontSize: "14px" }}>
            {classId || search
              ? record.firstName + " " + record.lastName
              : record.firstName + " " + record.LastName}
          </p>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Class",
      dataIndex: "newClassesList",
      key: "newClassesList",
      render: (_: any, record: teacherDetails) =>
        record?.newClassesList?.length > 0 ? (
          <Tooltip
            title={
              record?.newClassesList?.length > 0
                ? record?.newClassesList?.map((item) => (
                    <Tag
                      key={item.classId}
                      // style={{ backgroundColor: "#BDE55680", margin: "5px" }}
                      style={{
                        backgroundColor: "var(--primary-color)",
                        margin: "5px",
                      }}
                    >
                      {item.className}
                    </Tag>
                  ))
                : "-"
            }
            overlayInnerStyle={{ backgroundColor: "#F7F7F7", border: "none" }}
          >
            <Tag
              style={{
                width: "30px",
                padding: "5px",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                // backgroundColor: "black",
                backgroundColor: "var(--secondary-color)",
                color: "white",
              }}
            >
              {record?.newClassesList?.length}
            </Tag>
          </Tooltip>
        ) : (
          <Tag
            style={{
              width: "30px",
              padding: "5px",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              // backgroundColor: "black",
              backgroundColor: "var(--secondary-color)",
              color: "white",
            }}
          >
            {0}
          </Tag>
        ),
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle" style={{ display: "flex", margin: "0 5px" }}>
          {/* <div>
            <IoMdCreate fontSize={"18px"} cursor={"pointer"} />
          </div> */}
         
          <FiEdit2
            fontSize={"18px"}
            cursor={"pointer"}
            onClick={() => {setIsAddTeacherModalVisible(true);setisEdit(true);setData(record);
            }}
          />
          <div>
            <Popconfirm
              title="Delete Teacher"
              description="Are you sure to delete teacher ?"
              onConfirm={() => {
                teacherDelete(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <MdDeleteOutline
                fontSize={"18px"}
                cursor={"pointer"}
                color="red"
              />
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  const expandedCol: any = [
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      render: (_: string, obj: any) => (
        <>
          {obj?.subjects?.map((item: any) => {
            return (
              <Tag
                key={item.subjectId}
                color={"#DEF2AA"}
                style={{ color: "black" }}
              >
                {item.subjectName.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: string, obj: any) => (
        <>
          <AssignSubjectToTeacherModal
            classId={obj.classId}
            teacherId={obj.teacherId}
          />
        </>
      ),
    },
  ];

  const expandedRowRender = (record: any) => {
    return (
      <div className="exapandRow">
        {record.newClassesList && record.newClassesList.length > 0 ? (
          <div
            key={record.id}
            style={{
              padding: "20px",
            }}
          >
            <Table
              scroll={{ x: 600 }}
              dataSource={record?.newClassesList?.map(
                (item: any, key: number) => {
                  return {
                    key: key,
                    teacherId: item.teacherId,
                    classId: item.classId,
                    className: item.className,
                    subjects: item.subjects,
                  };
                }
              )}
              columns={expandedCol}
              pagination={false}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <AssignClassToTeacherModal teacherId={record.id} />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <IoFileTraySharp size={20} />
            <p>No subjects assigned.</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <AssignClassToTeacherModal teacherId={record.id} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return checkRoutePermissions("teacherPage") ? (
    <>
      <div className="mainDiv responsive">
        <div className="main_parent">
          <div>
            <GHeader
              heading="Teachers"
              numberOfPeople={`${count} People`}
              buttonText="Add a Teacher"
              onClick={() => {setIsAddTeacherModalVisible(true);setisEdit(false)}}
            />
            <hr />
            <div className="fliters_container">
              <Input
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                prefix={
                  <CiSearch
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                className="filter_search"
              />
              <div className="select_container">
                <Select
                  className="filter_select"
                  placeholder="Select Class"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setClassId(e);
                  }}
                  value={classId}
                  suffixIcon={
                    <RiExpandUpDownLine
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#667085",
                      }}
                    />
                  }
                >
                  {classes?.length > 0 &&
                    classes?.map((item: any) => {
                      return (
                        <Select.Option value={item.classId}>
                          {item.className}
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
              <div>
                <Button
                  className="btn_clear"
                  onClick={() => {
                    fetchTeachers();
                    setClassId(null);
                    setSearch("");
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
          {loading ? (
            <Flex
              align="center"
              justify="center"
              style={{ height: "50vh" }}
              gap="middle"
            >
              <Spin size="large" />
            </Flex>
          ) : (
            <>
              <div className="main_tab">
                <div className="table_container" style={{ marginTop: "10px" }}>
                  <Table
                    scroll={{ x: 600 }}
                    className="custom_table_design"
                    dataSource={allTeachers?.map((v, index) => {
                      return {
                        ...v,
                        key: index,
                      };
                    })}
                    columns={col}
                    pagination={false}
                    loading={loading}
                    expandable={{
                      expandedRowRender: (record) => expandedRowRender(record),
                      onExpand: handleExpand,
                      expandedRowKeys: expandedRowKeys,
                    }}
                  />
                </div>
              </div>
              <div className="main_pagination">
                <CustomPagination
                  currentPage={pages}
                  setCurrentPage={setPages}
                  total={allTeachers.length}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <TeacherSignupModal
        visible={isAddTeacherModalVisible}
        onCancel={setIsAddTeacherModalVisible}
        fetchTeachers={fetchTeachers}
        isEdit={isEdit}
        editable={data}
      />
    </>
  ) : (
    <NotAccess />
  );
};

export default TeacherNew;
