import React, { useEffect, useState } from "react";
import "../../styles/pages/teacherDashboard/teacherDashboard.css";
import { Row, Col, Button, Spin } from "antd";
import iconOne from "../../assets/teacherDashboardIconOne.png";
import iconTwo from "../../assets/teacherDashboardIconTwo.png";
import { useAppSelector } from "../../store/hooks";
import StudentPerformanceCard from "../../components/Cards/StudentPerformanceCard";
import WeekCalendar from "../../components/WeekCalendar";
import UpcomingClassesCard from "../../components/UpcomingClassesCard/UpcomingClassesCard";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  getStudentPerformance,
  getTopPerformer,
  getUpcomingClasses,
} from "../../store/services/dashboard";
import { setActiveClass } from "../../store/slices/dashboardSlice/teacherDashboardSlice";
import { fetchStudent, getPlannerClasses } from "../../store/services/PlannerModal";
import { Link } from "react-router-dom";

const TeacherDashboard = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [selectClass, setSelectClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [student, setStudnet] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoadig] = useState(false);
  const {
    upcomingLoading,
    activeClass,
    upcomingClassesData,
    topPerformerData,
  } = useAppSelector((state) => state.teacherDashboard);



  const fetchStudnetData = async () => {
    try {
      const res = await getPlannerClasses();
      setClasses(res?.data?.classes);
      setSelectClass(res?.data?.classes.length > 0 && res?.data.classes[0].classId);

    } catch (error) {
      console.log("error", error);
    }
  }

  console.log("classes4321", classes);

  const dispatch = useDispatch();


  useEffect(() => {
    _getData();
    fetchStudnetData();
  }, []);

  const _getData = () => {
    getStudentPerformance();
    getUpcomingClasses();
  };

  const fetchTopPerformer = async () => {
    try {
      setLoad(true);
      const payload = {
        classId: Number(selectClass)
      }
      const res = await getTopPerformer(payload);
      setLoad(false);
      if (res) {
        setStudnet(res.data.students);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchTopPerformer();
  }, [selectClass])

  return (
    <div className="_teacherDashboardWrapper">
      {
        loading ?
          <div className="clasDash_loader">
            <Spin />
          </div>
          :
          <div className="_teacherDashboard">
            {/* ----------> Left Section <----------*/}
            <div className="_leftSection">
              <div className="_leftSectionWrapper">
                <h2 className="_mainHeading">
                  Welcome back, {`${user?.firstName || "-"}`}{" "}
                  {`${user?.lastName || "-"}`}! 👋🏼
                </h2>
                <p className="_description">Review and manage the school data.</p>
                <div className="_mySubject">
                  <h2 className="_subHeading">My Subject</h2>
                  <div className="_subjectSectionBox">
                    <div className="_subjectSectionBoxWrapper">
                      <h4>Tajweed</h4>
                      <p>
                        Tajwīd or the science of tajwīd in Islam is a science by
                        which one learns the pronunciation of Qur'anic words as
                        pronounced by the Islamic prophet Muhammad.
                      </p>
                      <div className="_bottomContent">
                        <div>
                          <img src={iconOne} alt="Icon" />
                          <p>23 lessons</p>
                        </div>
                        <div>
                          <img src={iconTwo} alt="Icon" />
                          <p>345 students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="_subHeading">Classes</h2>
                  <div className="_classesSection">
                    {classes.length > 0 && classes?.map((v: any, i) => {
                      return (
                        <Button
                          key={v.classId}
                          className={selectClass === v.classId ? "activeButton" : ""}
                          onClick={() => setSelectClass(v.classId)}
                        >
                          {v.className}
                        </Button>
                      );
                    })}
                  </div>

                  <div className="_topPerformerHeader">
                    <h2 className="_subHeading" style={{ marginTop: 0 }}>
                      Top Performers
                    </h2>
                    <Link
                      to={{
                        pathname: "/dashboard/top-performers",
                      }}
                      state={{ topPerformer: student }}
                    >
                      <p style={{ cursor: "pointer" }}>View All</p>
                    </Link>
                  </div>
                  {
                    load ?
                      <div className="class_spin">
                        <Spin />
                      </div>
                      :
                      <div className="_topPerformerSection">
                        {student.length > 0 ? student?.slice(0, 3).map((item: any) => {
                          return (
                            <StudentPerformanceCard key={item?.id} data={item} />
                          );
                        }) :
                          <div>No Data Found</div>
                        }
                      </div>
                  }

                </div>
              </div>
              <div style={{ height: "20px" }} />
            </div>

            {/* ----------> Right Section <----------*/}
            <div className="_rightSection">
              <div className="_weekCalenderSection">
                <WeekCalendar />
              </div>
              <div className="_upcomingClassesSection">
                <h2>Upcoming Classes</h2>
                <div className="_upcomingClassesBody">
                  {upcomingLoading ? (
                    <div className="_noUpcomingClasses">
                      <Spin />
                    </div>
                  ) : upcomingClassesData?.length > 0 ? (
                    upcomingClassesData?.map((v: any, i: number) => {
                      return <UpcomingClassesCard key={i} data={v} />;
                    })
                  ) : (
                    <div className="_noUpcomingClasses">
                      <p>No upcoming classes found!</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      }

    </div>
  );
};

export default TeacherDashboard;





