import { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";

import clockIcon from "../../assets/Clock.svg";
import { ScheduleType } from "../../store/interfaces/schedule";

import { GoDotFill } from "react-icons/go";
import { RiExpandUpDownLine } from "react-icons/ri";
import { useAppSelector } from "../../store/hooks";
import CreateSchedule from "../../components/Modals/CreateSchedule";

import { BsTrash } from "react-icons/bs";
import { PiWarningCircleFill } from "react-icons/pi";
import {
  deleteSchedule,
  getAdminSchedule,
} from "../../store/services/schedule";
import { checkRoutePermissions } from "../../utils/permission";
import { getAllClasses } from "../../store/services/class";
import SearchableDropdown from "../../components/SearchableDropdown";

const { confirm } = Modal;

interface Props {
  days: String[];
  times: String[];
}

const ScheduleBigScreen = ({ days, times }: Props) => {
  const { schedules } = useAppSelector((state) => state.schedule);
  const [classId, setClassId] = useState<null | number>(null);
  const [classes, setClasses] = useState([]);

  const fetchClasses = async () => {
    try {
      const res = await getAllClasses();
      setClasses(res?.records);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const getShedule = async () => {
      try {
        if (classId) {
          await getAdminSchedule(classId);
        } else {
          await getAdminSchedule();
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getShedule();
  }, [classId]);

  useEffect(() => {
    fetchClasses();
  }, []);

  return (
    <div className="_scheduleContainer responsive">
      <div className="_scheduleHeader">
        <div className="_left">
          <h2>Schedule</h2>
        </div>
        {checkRoutePermissions("createSchedule") && (
          <div className="_right">
            <SearchableDropdown
              url="/class/get-all"
              mode={false}
              placeholder="Select class"
              columns={["className"]}
              defaultValue={!classId ? undefined : classId}
              type="shedule"
              onChange={(e: any) => setClassId(e)}
              params={{status:"active"}}
            />
            {classId && <Button className="btn_clear" onClick={()=>setClassId(null)}>Clear</Button>}
            <CreateSchedule />
          </div>
        )}
      </div>
      <div className="_scheduleBoxWrapper">
        <div className="_scheduleBox">
          <div className="_headerRow">
            <div className="_headerChildFirst" />
            {days.map((v, i) => {
              return (
                <div key={i} className="_headerChildOthers">
                  <p>{v}</p>
                </div>
              );
            })}
          </div>
          {times.map((v, vIndex) => (
            <div key={vIndex} className="_dataRow">
              <div className="_dataChildFirst">
                <p>{v}</p>
              </div>
              {days.map((day, dayIndex) => (
                <div className="_dataChildOthers" key={dayIndex}>
                  {schedules?.map((data, dataIndex) => {
                    if (
                      data?.startTime >= v &&
                      (vIndex === times.length - 1 ||
                        data?.startTime < times[vIndex + 1]) &&
                      data?.day.toLowerCase() === day.toLowerCase()
                    ) {
                      return <ScheduleCard key={dataIndex} data={data} />;
                    }
                  })}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ScheduleCard = ({ data }: { data: ScheduleType }) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getShortName = (str: string) => {
    if (str?.length > 5) {
      return `${str.slice(0, 4)}..`;
    } else {
      return str;
    }
  };

  const showPromiseConfirm = () => {
    confirm({
      title: "Are you sure you want to delete?",
      icon: (
        <PiWarningCircleFill
          size={30}
          color="orange"
          style={{ marginRight: "10px" }}
        />
      ),
      content:
        "This item will be deleted immediately. You can't undo this action.",
      async onOk() {
        await deleteSchedule({ scheduleId: data?.scheduleId });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div
        className="_scheduleCard"
        style={{
          borderColor: `rgb(${data?.color})`,
          backgroundColor: `rgba(${data?.color},0.1)`,
        }}
      >
        <div className="_scheduleCardTop">
          <img src={clockIcon} alt="C" />
          <p>
            {data?.startTime} - {data?.endTime}
          </p>
        </div>
        <div className="_scheduleCardBottom">
          {width > 1200 ? (
            <p>
              {getShortName(data?.subjectName)}
              <GoDotFill
                color={`rgb(${data?.color})`}
                size={7}
                style={{ marginInline: "2px" }}
              />
              {getShortName(data?.firstName)}
              <GoDotFill
                color={`rgb(${data?.color})`}
                size={7}
                style={{ marginInline: "2px" }}
              />
              {data?.className?.slice(6)}
            </p>
          ) : (
            <p>{data?.className}</p>
          )}
        </div>
        <div className="_info">
          {/* For View Only */}
          {checkRoutePermissions("viewSchedule") && (
            <CreateSchedule view={true} editData={data} />
          )}

          {/* For Update Only */}
          {checkRoutePermissions("updateSchedule") && (
            <CreateSchedule editData={data} />
          )}

          {checkRoutePermissions("deleteSchedule") && (
            <BsTrash
              size={18}
              style={{ cursor: "pointer" }}
              onClick={showPromiseConfirm}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleBigScreen;
