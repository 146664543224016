import { useEffect, useState } from "react";
import { Button, Input, Modal, Space, Spin, Tooltip } from "antd";
import {
  IoIosArrowRoundBack,
  IoIosSearch,
  IoMdArrowBack,
  IoMdArrowForward,
} from "react-icons/io";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { getSubjectByClass } from "../../../store/services/subject";
import { firstLetterCap } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setActiveFilter,
  setActiveSubject,
  setCurrentIndex,
  setCurrentLevel,
  setLevels,
} from "../../../store/slices/Classes/classSlice";
import { getAllStudentsByClassSubject } from "../../../store/services/student";
import {
  createProgress,
  getLevelBySubject,
  getStudentProgressByLevel,
  updateStudentLevel,
} from "../../../store/services/class";
import { setStudentsByClassSubject } from "../../../store/slices/students/studentSlice";
import {
  LessonType,
  LevelType,
  ModuleType,
  ResetStatusType,
  StatusType,
} from "../../../types/interface/level";
import { SubjectType } from "../../../types/interface/subject";
import ProgressNoteModal from "../../../components/Modals/ProgressNoteModal";
import { BiRefresh } from "react-icons/bi";

import { setActiveProcess } from "../../../store/slices/Classes/activeSlice";
import { useSelector } from "react-redux";
import useBackButtonBlocker from "../../../Hook/useHistoryBlocker";

const DesktopView = () => {
  const { subjects, activeSubject, levels, activeFilter, currentLevel } =
    useAppSelector((state) => state.class);
  const { studentsByClassSubject } = useAppSelector((state) => state.student);
  console.log("studentsByClassSubject", studentsByClassSubject);
  const [isLoading, setIsLoading] = useState<string>("");
  const [updateStatus, setUpdateStatus] = useState<Array<StatusType>>([]);
  const [noteModal, setNoteModal] = useState<boolean>(false);
  const [noteModalData, setNoteModalData] = useState<any>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { classId } = useParams();

  const isActive = useSelector((state: any) => state.active.activeProcessing);

  useEffect(() => {
    _getInitial();

    const cleanup = () => {
      dispatch(setActiveSubject(null));
    };

    return cleanup;
  }, [classId]);

  // useEffect(() => {
  //   console.log("======>", currentLevel);
  // }, [currentLevel]);

  // useBackButtonBlocker(isActive, "Are you sure you want to leave this page?");


  const _getInitial = async () => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId) {
      setIsLoading("initial");
      const _subjects = await getSubjectByClass({ classId: Number(classId) });
      if (_subjects?.subjects?.length > 0) {
        dispatch(setActiveSubject(_subjects?.subjects?.[0]));
        const subjectId = _subjects?.subjects?.[0]?.subjectId;
        await getAllStudentsByClassSubject({
          classId: Number(classId),
          subjectId,
        });
        const _level: any = await getLevelBySubject({ subjectId });
        if (_level?.length > 0)
          await getStudentProgressByLevel({
            levelId: _level?.[0]?.levelId,
          });
      }
      setIsLoading("");
    }
  };

  const _getData = async (_subject: SubjectType) => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId && _subject) {
      setIsLoading("level");
      const subjectId = _subject?.subjectId;
      await getAllStudentsByClassSubject({
        classId: Number(classId),
        subjectId,
      });
      const _level = await getLevelBySubject({ subjectId });
      if (_level?.length > 0)
        await getStudentProgressByLevel({
          levelId: _level?.[0]?.levelId,
        });
      setIsLoading("");
    }
  };

  const _getProgress = async (levelId: number) => {
    setIsLoading("level");
    await getStudentProgressByLevel({
      levelId,
    });
    setIsLoading("");
  };

  const handleChangeLevel = async (levelId: number) => {
    dispatch(setCurrentLevel([]));
    await getStudentProgressByLevel({
      levelId,
    });
  };

  const getColor = (value: string | null | undefined) => {
    switch (value) {
      case "pass":
        return "rgb(83, 194, 37)";
      case "fail":
        return "rgba(234, 67, 54, 1)";
      case "in-progress":
        return "rgba(255, 217, 102, 1)";
      default:
        return "#fff";
    }
  };

  const _handleStatusUpdate = (obj: StatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId
    );
    if (index !== -1) {
      temp.splice(index, 1, obj);
    } else {
      temp.push(obj);
    }
    setUpdateStatus(temp);
    dispatch(setActiveProcess(true));
  };

  const _handleStatusReset = (obj: ResetStatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId
    );
    if (index !== -1) {
      temp.splice(index, 1);
    }
    setUpdateStatus(temp);
    dispatch(setActiveProcess(true));
  };

  const handleSaveProgress = async () => {
    setIsLoading("progress");

    console.log("updateStatus", classId, activeSubject);

    const res = await createProgress({ progress: updateStatus });
    if (res) {
      const levelRes = await updateStudentLevel({
        classId: classId,
        subjectId: activeSubject?.subjectId,
      });

      if (levelRes) {
        updateStatus.length > 0 &&
          (await _getProgress(updateStatus[0].levelId));
        setUpdateStatus([]);
        dispatch(setActiveProcess(false));
      }
    }
    setIsLoading("");
  };

  const handleOpenNoteModal = (obj: any) => {
    setNoteModal(true);
    setNoteModalData(obj);
  };
  const handleCancelNoteModal = () => {
    setNoteModal(false);
    setNoteModalData(null);
  };
  const moveBack = () => {
    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return; // Prevent navigation if the user cancels
      }
      dispatch(setActiveProcess(false));
    } else {
      navigate(-1);
    }
  };
  const moveTabs = (subj: any) => {
    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return; // Prevent navigation if the user cancels
      }
      dispatch(setActiveProcess(false));
      setUpdateStatus([]);
    } else {
      _getData(subj);
      dispatch(setActiveSubject(subj));
    }
  };
  return (
    <div className="_progressContainer">
      <div className="_progressHeader">
        <div className="_headingBox">
          <h2>
            {" "}
            <IoIosArrowRoundBack
              style={{ marginBottom: "-7px", cursor: "pointer" }}
              size={35}
              onClick={() => moveBack()}
            />{" "}
            Classes
          </h2>
          <p>Review and manage the classes.</p>
        </div>
        {/* <Input
          className="_searchInput"
          placeholder="Search..."
          prefix={<IoIosSearch />}
        /> */}
        {/* <div className="_buttonGroup">
          <Button
            type="primary"
            onClick={() => dispatch(setActiveFilter("full-class"))}
            className={activeFilter === "full-class" ? "active" : ""}
          >
            Full class
          </Button>
          <Button
            type="primary"
            onClick={() => dispatch(setActiveFilter("groups"))}
            className={activeFilter === "groups" ? "active" : ""}
          >
            Groups
          </Button>
        </div> */}
        {/* <Button
          className="_addClassBtn"
          type="primary"
          icon={
            <FiPlus color="#fff" size={20} style={{ marginBottom: "-5px" }} />
          }
        >
          Add a Class
        </Button> */}
      </div>

      <div className="_subjectContainer">
        {subjects?.map((subj) => {
          return (
            <div
              onClick={() => {
                moveTabs(subj);
              }}
              key={subj?.subjectId}
              className={
                activeSubject?.subjectId === subj?.subjectId
                  ? "_subjectButtonActive"
                  : "_subjectButton"
              }
            >
              <p>{firstLetterCap(subj?.subjectName)}</p>
            </div>
          );
        })}
      </div>
      {isLoading === "initial" ? (
        <div className="_loadingSpinner">
          <Spin />
        </div>
      ) : subjects?.length > 0 ? (
        <section>
          <div className="_userSection">
            <div className="_top">
              {/* ****************************************************************** */}
              {updateStatus?.length > 0 && (
                <>
                  <Button
                    loading={isLoading === "progress"}
                    onClick={handleSaveProgress}
                  >
                    Save Progress
                  </Button>
                  <Button
                    onClick={() => {
                      setUpdateStatus([]);
                      dispatch(setActiveProcess(false));
                    }}
                  >
                    Clear
                  </Button>
                </>
              )}
            </div>
            {/* {studentsByClassSubject?.map((v, i) => { */}
            {studentsByClassSubject
              ?.filter(
                (student: any) =>
                  Number(student?.levelId) >= Number(currentLevel[0]?.levelId)
              )
              ?.map((v, i) => {
                const bg = i % 2 == 1;
                return (
                  <div
                    className="_userBox"
                    key={i}
                    style={{
                      backgroundColor: bg ? "rgba(250, 251, 253, 1)" : "#fff",
                    }}
                  >
                    <p>
                      {firstLetterCap(v?.firstName)}{" "}
                      {firstLetterCap(v?.lastName)}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="_progressSection">
            <div className="_levelContainer">
              {levels?.map((v: LevelType, i: number) => {
                return (
                  <div
                    key={i}
                    className="_levelBox"
                    style={{ backgroundColor: `rgb(${v?.color})` }}
                    onClick={() => handleChangeLevel(v?.levelId)}
                  >
                    <span>{v?.levelName}</span>
                  </div>
                );
              })}
            </div>
            {isLoading === "level" ? (
              <div className="_loadingSpinner2">
                <Spin />
              </div>
            ) : (
              <div className="_levelContent">
                {currentLevel?.length ? (
                  <>
                    <div
                      className="_lcHeader"
                      style={{
                        backgroundColor: `rgb(${
                          levels?.find(
                            (lev: LevelType) =>
                              lev?.levelId === currentLevel?.[0]?.levelId
                          )?.color
                        })`,
                      }}
                    >
                      <p>
                        {
                          levels?.find(
                            (lev: LevelType) =>
                              lev?.levelId === currentLevel?.[0]?.levelId
                          )?.levelName
                        }
                      </p>
                    </div>
                    <div className="_levelContentBody">
                      {currentLevel?.map((module: ModuleType, i: number) => {
                        return (
                          <div key={i} className="_hCard">
                            <div className="_hCardHeader">
                              <p>{firstLetterCap(module?.moduleName)}</p>
                            </div>
                            <div className="_hCardContent">
                              {module?.lessons?.map(
                                (lesson: LessonType, i: number) => {
                                  const bg = i % 2 == 1;
                                  return (
                                    <div key={i} className="_vCard">
                                      <div
                                        className="_vCardHeader"
                                        style={{
                                          backgroundColor: bg
                                            ? "rgba(250, 251, 253, 1)"
                                            : "#fff",
                                        }}
                                      >
                                        <p>
                                          {lesson?.lessonName?.length > 35
                                            ? `${lesson?.lessonName?.slice(
                                                0,
                                                35
                                              )}...`
                                            : lesson?.lessonName}
                                        </p>
                                      </div>
                                      {studentsByClassSubject
                                        ?.filter(
                                          (student: any) =>
                                            Number(student?.levelId) >=
                                            Number(currentLevel[0]?.levelId)
                                        )
                                        ?.map((v: any, i: number) => {
                                          const _studentForUpdate =
                                            updateStatus?.find(
                                              (x) =>
                                                x?.lessonId ===
                                                  lesson?.lessonId &&
                                                x?.studentId === v?.studentId &&
                                                x?.levelId ===
                                                  module?.levelId &&
                                                x?.moduleId === module?.moduleId
                                            );

                                          const _student =
                                            lesson?.progress?.find(
                                              (x) =>
                                                v?.studentId == x?.studentId
                                            );
                                          const _obj: any = {
                                            lessonId: lesson?.lessonId,
                                            studentId: v?.studentId,
                                            levelId: module?.levelId,
                                            moduleId: module?.moduleId,
                                          };

                                          const isPassButton =
                                            _studentForUpdate?.status ===
                                              "pass" ||
                                            _student?.status === "pass";
                                          const isFailButton =
                                            _studentForUpdate?.status ===
                                              "fail" ||
                                            _student?.status === "fail";
                                          const isInProgressButton =
                                            _studentForUpdate?.status ===
                                              "in-progress" ||
                                            _student?.status === "in-progress";

                                          return (
                                            <Tooltip
                                              placement="top"
                                              title={
                                                <div className="_statusButtonBox">
                                                  {!isPassButton && (
                                                    <Button
                                                      style={{
                                                        background:
                                                          getColor("pass"),
                                                      }}
                                                      onClick={() =>
                                                        _handleStatusUpdate({
                                                          ..._obj,
                                                          status: "pass",
                                                        })
                                                      }
                                                    >
                                                      Pass
                                                    </Button>
                                                  )}
                                                  {!isFailButton && (
                                                    <Button
                                                      style={{
                                                        background:
                                                          getColor("fail"),
                                                      }}
                                                      onClick={() =>
                                                        _handleStatusUpdate({
                                                          ..._obj,
                                                          status: "fail",
                                                        })
                                                      }
                                                    >
                                                      Fail
                                                    </Button>
                                                  )}
                                                  {!isInProgressButton && (
                                                    <Button
                                                      style={{
                                                        background:
                                                          getColor(
                                                            "in-progress"
                                                          ),
                                                      }}
                                                      onClick={() =>
                                                        _handleStatusUpdate({
                                                          ..._obj,
                                                          status: "in-progress",
                                                        })
                                                      }
                                                    >
                                                      In Progress
                                                    </Button>
                                                  )}
                                                  {_studentForUpdate?.status && (
                                                    <BiRefresh
                                                      size={30}
                                                      color={"#fff"}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        _handleStatusReset({
                                                          ..._obj,
                                                        });
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              }
                                            >
                                              <div
                                                key={i}
                                                className="_studentColorBox"
                                                style={{
                                                  backgroundColor: getColor(
                                                    _studentForUpdate?.status ||
                                                      _student?.status
                                                  ),
                                                }}
                                                onDoubleClick={() =>
                                                  handleOpenNoteModal({
                                                    value: v,
                                                    studentId: v?.studentId,
                                                    studentName: `${v?.firstName} ${v?.lastName}`,
                                                    imageUrl: v?.imageUrl,

                                                    className: v?.className,

                                                    levelId: module?.levelId,

                                                    subjectId: v?.subjectId,
                                                    subjectName: subjects?.find(
                                                      (x) =>
                                                        x?.subjectId ===
                                                        v?.subjectId
                                                    )?.subjectName,

                                                    lessonId: lesson?.lessonId,
                                                    lessonName:
                                                      lesson?.lessonName,

                                                    progress:
                                                      _studentForUpdate ||
                                                      _student,
                                                  })
                                                }
                                              >
                                                {_student?.notes && (
                                                  <div
                                                    className="_cornerNoteSign"
                                                    style={{
                                                      borderTop: `10px solid ${
                                                        isPassButton
                                                          ? "rgba(8, 93, 58, 1)"
                                                          : isInProgressButton
                                                          ? "rgba(181, 71, 8, 1)"
                                                          : "transparent"
                                                      }`,
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </Tooltip>
                                          );
                                        })}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="_noDataFound">
                    <p>No data found!</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      ) : (
        <div className="_emptyText">
          <p>No subject found!</p>
        </div>
      )}
      <ProgressNoteModal
        open={noteModal}
        close={handleCancelNoteModal}
        data={noteModalData}
      />
    </div>
  );
};

export default DesktopView;
