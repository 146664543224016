import { attachToken, privateAPI, publicAPI } from "../../config/constants";
import store from "..";
import {
  setClasses,
  setCurrentLevel,
  setLevels,
  setLoadingClasses,
  setSubjectsByClass,
} from "../slices/Classes/classSlice";
import { setLoadingTeachers } from "../slices/Teachers/teacherSlice";
import { notification } from "antd";

export const createClass = async (data: { className: string }) => {
  console.log(data.className);
  try {
    console.log(data.className);
    const response = await privateAPI.post("/class/create", data);
    return new Promise<{
      classId: number;
      className: string;
      noOfTeachers: string;
      noOfStudents: string;
      subjects: string[];
    }>((resolve) =>
      setTimeout(() => {
        resolve({
          classId: Math.floor(Math.random() * 1000), // Generating a random ID for the class
          className: data.className,
          noOfTeachers: "N/A",
          noOfStudents: "N/A",
          subjects: [],
        });
      }, 1000)
    );
    // console.log("api k bad",response?.data)
    // return response?.data;
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};

export const getAllClasses = async (data?: any) => {
  try {
    store.dispatch(setLoadingClasses(true));
    const response = await privateAPI.post("/class/get-all", data);
    if (response) {
      store.dispatch(setClasses(response?.data));
      console.log(response.data);
      return response?.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoadingClasses(false));
  }
};

export const getClassOne = async (classId: string | number) => {
  try {
    store.dispatch(setLoadingClasses(true));
    const response = await privateAPI.post("/class/get-single", { classId });
    if (response) {
      console.log(
        "🚀 ~ getClassOne ~ response:",
        response?.data?.singleClass?.[0]
      );
    }
    return response?.data;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoadingClasses(false));
  }
};

export const updateClass = async (data: any) => {
  try {
    const res = await privateAPI.post("/class/update", data);
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const removeSubjectFromClass = async ({
  subjectId,
  classId,
}: {
  subjectId: number;
  classId: number;
}) => {
  try {
    const res = await privateAPI.post("/class/remove-subject", {
      subjectId,
      classId,
    });
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteClass = async (id: number) => {
  try {
    const res = await privateAPI.post("/class/delete", { classId: id });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

// ARCHIVED API

export const addArchive = async (data: any) => {
  try {
    await privateAPI.post("/class/archive", data);
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
export const unArchive = async (data: any) => {
  try {
    await privateAPI.post("/class/unarchive", data);
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};

// CLASSES PROGRESS
export const createProgress = async (payload: any) => {
  try {
    const response = await privateAPI.post("/progress/create", payload);
    if (response) {
      // await callback();
    }
    return response;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const updateStudentLevel = async (payload: any) => {
  try {
    const response = await privateAPI.post("/progress/update-level", payload);
    if (response) {
      // await callback();
    }
    return response;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getStudents = async (payload: any) => {
  try {
    const response = await privateAPI.post("/progress/create", payload);
    if (response) {
    }
    return response?.data;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getProgressHistory = async (payload: any) => {
  try {
    const response = await privateAPI.post(
      "/progress/get-progress-history",
      payload
    );
    return response?.data?.records;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getStudentProgressByLevel = async (payload: any) => {
  try {
    const response = await privateAPI.post(
      "/progress/get-student-progress-by-level",
      payload
    );
    if (response) {
      store.dispatch(setCurrentLevel(response?.data?.modules));
    }
    return response?.data;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getLevelBySubject = async (payload: any) => {
  try {
    const response = await privateAPI.post(
      "/level/get-level-by-subject",
      payload
    );
    if (response?.data?.level?.length > 0) {
      store.dispatch(setLevels(response?.data?.level));
      // store.dispatch(setCurrentLevel(response?.data?.level?.[0]));
    }
    return response?.data?.level;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getAllSubjects = async () => {
  try {
    const res = await privateAPI.post("/subject/get-all");
    store.dispatch(setSubjectsByClass(res?.data?.subjectList));
    return res?.data?.subjectList;
  } catch (error) {
    console.log("error classes", error);
  }
};

export const createClasses = async (data: {
  className: string;
  subjectIds: number[];
}) => {
  try {
    const res = await privateAPI.post("/class/create", data);
    if (res.status === 200) {
      notification.success({ message: "class created successfully" });
      return res;
    }
  } catch (error) {
    console.log("error class", error);
  }
};

export const createProgressNote = async (data: any) => {
  try {
    const res = await privateAPI.post("/progress/add-notes", data);
    notification.success({ message: "Added successfully" });
    return res;
  } catch (error) {
    console.log("error class", error);
  }
};

export const fetchByClassName = async (data: any) => {
  try {
    store.dispatch(setLoadingClasses(true));
    const res = await privateAPI.post("/class/get-all", data);
    if (res.status === 200) {
      store.dispatch(setClasses(res?.data));
      store.dispatch(setLoadingClasses(true));
      return res?.data;
    }
  } catch (error) {
    console.log("erro", error);
  } finally {
    store.dispatch(setLoadingClasses(false));
  }
};

export const getStudentSubjects = async () => {
  try {
    const res = await privateAPI.get("/class/get-student-class");
    if (res.status === 200) {
      return res?.data;
    }
  } catch (error) {
    console.log("erro", error);
  }
};
