import React from "react";
import { Avatar, Button } from "antd";
import { IoMdHeart } from "react-icons/io";
import "../../styles/pages/linktree.css";
function LinkTree() {
  return (
    <div className="container_linktree">
      
      <div className="profile">
        <Avatar
          size={120}
          src="https://png.pngtree.com/png-vector/20230831/ourmid/pngtree-man-avatar-image-for-profile-png-image_9197908.png"
        />
      </div>
      <div className="top-heading">
        <h1 className="greeting">Hi, I'm Jane!</h1>
        <p className="intro-text">Check out my links!</p>
      </div>

      <div className="social_links">
        <h1>My Social Networks</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Facebook
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Twitter
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Github
        </Button>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          LinkedIn
        </Button>
      </div>

      <div className="details_link">
        <h1>My CV</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Curriculum Vitae
        </Button>
      </div>

      <div className="details_link">
        <h1>My Blog</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Hashnode
        </Button>
      </div>

      <div className="details_link">
        <h1>My Business</h1>
        <Button
          className="linkButton"
          type="primary"
          shape="round"
          size="large"
        >
          Business
        </Button>
      </div>

      <footer className="footer">
        <IoMdHeart className="heart" /> <p>2021 - Jane Doe</p>{" "}
        <IoMdHeart className="heart" />
      </footer>
    </div>
  );
}

export default LinkTree;
