import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { createAdmin, updateAdmin } from "../../store/services/admin";

interface AdminCreateUpdateModalProps {
  visible: boolean;
  onCancel: (value: boolean) => void;
  isEditMode?: boolean | undefined;
  editObj?: any;
  fetchAdmins?: () => void;
}
interface SignupFormValues {
  salutation: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  className: string;
  remember?: string;
  image: { file: File };
}

const { Option } = Select;

const salutationOptions = ["Male", "Female"];
function AdminCreateUpdate({
  visible,
  onCancel,
  isEditMode,
  editObj,
  fetchAdmins,
}: AdminCreateUpdateModalProps) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values: SignupFormValues) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("salutation", values.salutation);
      formData.append("firstName", values.firstname);
      formData.append("lastName", values.lastname);
      formData.append("email", values.email);
      formData.append("password", values.password);
      values?.phone && formData.append("phoneNumber", values.phone);
      values?.image?.file && formData.append("pictures", values.image.file);
      if (isEditMode) {
        formData.append("id", editObj.id);
        await updateAdmin(formData);
      } else {
        await createAdmin(formData);
      }
      fetchAdmins && fetchAdmins();
      form.resetFields();
      onCancel(false);
    } catch (error) {
      console.error("Failed:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isEditMode && editObj) {
      form.setFieldsValue({
        salutation: editObj?.salutation,
        firstname: editObj?.firstName,
        lastname: editObj.lastName,
        email: editObj?.email,
        password: editObj?.normalPassword,
        phone: editObj?.phoneNumber,
      });
    }
  }, [isEditMode,editObj]);

  return (
    <Modal open={visible} onCancel={() => onCancel(false)} footer={null}>
      <Row>
        <Col span={24}>
          <div className="modalheading">
            {isEditMode ? <h1>Edit Admin</h1> : <h1>Add Admin</h1>}
          </div>
          <p>Please enter details.</p>
          <br />
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="salutation"
              label="Salutation"
              rules={[
                { required: true, message: "Please select your salutation" },
              ]}
            >
              <Select
                style={{ width: "100%", borderRadius: "8px" }}
                dropdownStyle={{
                  backgroundColor: "white",
                  border: "1px solid #d9d9d9",
                }}
              >
                {salutationOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <input className="input" placeholder="Enter Your First Name" />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <input className="input" placeholder="Enter Your Last Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter your email",
                },
              ]}
            >
              <input className="input" placeholder="Enter your Email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <input
                type="password"
                className="input"
                placeholder="Enter your Password"
              />
            </Form.Item>
            <Form.Item name="phone" label="Phone Number">
              <input className="input" placeholder="Enter your Phone Number" />
            </Form.Item>

            <Form.Item name="image" label="Upload Image">
              <Upload beforeUpload={() => false} listType="picture">
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Space>
              <Button className="cancelbtn" onClick={() => onCancel(false)}>
                Cancel
              </Button>
              <Button htmlType="submit" className="addbtn" loading={loading}>
                {isEditMode ? "Update" : "Add"}
              </Button>
            </Space>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default AdminCreateUpdate;
