import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Row, Form, notification } from "antd";
import "../../styles/pages/studentoverview.css";
import { MdOutlineEdit, MdAddBox } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoPersonOutline } from "react-icons/io5";
import { BsEmojiLaughing } from "react-icons/bs";
import {
  deleteStudentNote,
  getAllStudentNote,
  getStudent,
} from "../../store/services/student";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryModal from "../../components/Modals/studentBrief/CategoryModal";
import NoteModal from "../../components/Modals/studentBrief/NoteModal";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
// import { FormInstance } from "antd";

// import { BsGraphUpArrow } from "react-icons/bs";

interface StudentCatPoints {
  studentId: string;
}

interface AboutTheStudent {
  categoryName: string;
  studentCatPoints?: StudentCatPoints[];
  studentId: number;
  categoryId: number;
}

const collapseDate = "11 September, 2024";
const contentOne =
  "Rachel is introverted and bookish, preferring solitude and quiet activities like reading and writing.";

const contentTwo =
  "She is driven by a desire to succeed academically and to prove herself to her parents, who have high expectations for her.";

const contentThree =
  "She is driven by a desire to succeed academically and to prove herself to her parents, who have high expectations for her.";

interface NoteData {
  studentId: number | null;
  category: any;
  note: any;
  isNoteModalOpen: boolean;
  isNoteModalEditMode: boolean;
}

const ClassesStudent: React.FC = () => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [aboutStudent, setAboutStudent] = useState<AboutTheStudent[]>([]);
  const [noteData, setNoteData] = useState<NoteData>({
    studentId: null,
    category: null,
    note: null,
    isNoteModalOpen: false,
    isNoteModalEditMode: false,
  });
  const allNotes = useAppSelector((state) => state?.student?.allNotes);
  const location = useLocation();
  const receivingStateId = location?.state?.studentId;

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const fetchStudents = async () => {
    const payload = {
      studentId: receivingStateId,
    };
    const data: any = await getStudent(payload);
    setAboutStudent(data.newStudentCategory as AboutTheStudent[]);
  };

  const getAllnote = async () => {
    try {
      await getAllStudentNote(receivingStateId);
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteNote = async (noteId: number) => {
    try {
      const res = await deleteStudentNote(noteId);
      await getAllnote();
      res &&
        notification.success({
          message: "Note deleted successfully",
        });
    } catch (e) {
      console.log(e);
    }
  };

  const items = aboutStudent.map((item, index) => ({
    key: `${index}`,
    label: (
      <span>
        {item?.categoryName === "character" ? (
          <IoPersonOutline className="personOutlined" />
        ) : (
          <BsEmojiLaughing className="emojiLaughing" />
        )}
        {item?.categoryName}
        <MdAddBox
          className="add-btn"
          onClick={(e: any) => {
            e.stopPropagation();
            setNoteData({
              ...noteData,
              studentId: receivingStateId,
              category: { ...item },
              isNoteModalOpen: true,
            });
          }}
        />
      </span>
    ),
    children: (
      <div>
        {allNotes
          ?.filter((note: any) => note.categoryId === item.categoryId)
          ?.map((note: any) => (
            <Row
              key={note.noteId}
              justify="space-between"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Col lg={22}>
                <div>
                  <h6>{moment(note.createdAt).format("DD MMMM, YYYY")}</h6>
                  <p className="content-one">{note.note}</p>
                </div>
              </Col>
              <Col lg={2}>
                <Row>
                  <Col lg={12}>
                    <Button
                      className="collapseEditBtn"
                      onClick={() => {
                        setNoteData({
                          ...noteData,
                          studentId: receivingStateId,
                          category: { ...item },
                          note: { ...note },
                          isNoteModalOpen: true,
                          isNoteModalEditMode: true,
                        });
                      }}
                    >
                      <MdOutlineEdit />
                    </Button>
                  </Col>
                  <Col lg={12}>
                    <Button
                      className="collapseDeleteBtn"
                      onClick={() => deleteNote(note.noteId)}
                    >
                      <RiDeleteBin6Line />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}

        {/* Repeat for other contents if needed */}
      </div>
    ),
  }));

  useEffect(() => {
    if (!receivingStateId) {
      navigate("/students");
    } else {
      fetchStudents();
      getAllnote();
    }
  }, [dispatch]);

  return (
    <>
      <div className="classesStd">
        <Row justify={"space-between"}>
          <Col lg={21}>
            <h1>Student Brief</h1>
          </Col>
          <Col lg={3}>
            <button
              onClick={() => setIsCategoryModalOpen(true)}
              className="addStdCat"
            >
              Add Category
            </button>
          </Col>
        </Row>
        <Collapse
          className="project-collapse"
          defaultActiveKey={["0"]}
          onChange={onChange}
          items={items}
        />
      </div>
      <NoteModal
        visible={noteData.isNoteModalOpen}
        onCancel={() =>
          setNoteData({
            ...noteData,
            note: null,
            category: null,
            studentId: null,
            isNoteModalOpen: false,
            isNoteModalEditMode: false,
          })
        }
        data={noteData}
        editMode={noteData.isNoteModalEditMode}
      />
      <CategoryModal
        visible={isCategoryModalOpen}
        onCancel={() => setIsCategoryModalOpen(false)}
      />
    </>
  );
};

export default ClassesStudent;
