import { Button, Col, Form, Modal, Row, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { isMobile } from "../../../config/constants";
import SearchableDropdown from "../../SearchableDropdown";
import { getSubjectListByClassDropdown } from "../../../store/services/subject";
import { useAppSelector } from "../../../store/hooks";
import { AssignSubjectsToClass, getAllTeachersList } from "../../../store/services/teacher";

interface Props {
  teacherId?: number;
}

const AssignClassToTeacherModal: React.FC<Props> = ({
  teacherId,
}) => {
  const { loadingSubjectList, subjectListByClass } = useAppSelector(
    (state) => state.subjectListByClassSlice
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [selectedSubjectValue, setSelectedSubjectValue] = useState<
    string | string[]
  >();
  const [classId, setClassId] = useState<number>();

  const handleShowModal = (): void => {
    setShowModal(true);
  };

  const getSubjects = async () => {
    const payload = {
      classId: classId,
    };
    await getSubjectListByClassDropdown(payload);
  };

  const handleSelectSubjectChange = (value: string | string[]) => {
    setSelectedSubjectValue(value);
  };

  const handleCancel = (): void => {
    setShowModal(false);
    handleReset();
  };

  const onFinish = async (values: any) => {
    const payload = {
      subjectIds: selectedSubjectValue,
      teacherId: teacherId,
      classId: classId,
    };
    setLoading(true);
    const res = await AssignSubjectsToClass(payload);
    if(res) {
        setLoading(false);
        handleReset();
        const payload = {
            page: 1,
            perPage: 10,
            search:""
          };
          await getAllTeachersList(payload);
          handleCancel();
    }
  };

  const handleReset = (): void => {
    form.resetFields();
    setSelectedSubjectValue([]);
  };

  const handleChange = (value: any) => {
    console.log("🚀 ~ handleChange ~ value:", value)
    setClassId(value);
    setSelectedSubjectValue([]);
    form.setFieldsValue({ subjects: [] });
  };

  useEffect(() => {
    console.log("CLASS ID CHange", classId);
    if(classId){
      getSubjects();
    }
  }, [classId]);


  return (
    <div>
      <p onClick={handleShowModal} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px' }}><FaPlusCircle size={15} />{ "Add Class"}</p>
      <Modal
        forceRender
        title={"Assign Class to Teacher"}
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        className="create_role_modal"
        centered
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <Row gutter={[20, 0]}>
            <Col xs={24} >
              <Form.Item  name="classId"
                label="Select Class to Assign">
                <SearchableDropdown
                    mode={false}
                    url="/class/get-all"
                    onChange={handleChange}
                    columns={["className"]}
                    placeholder="Enter Class name to search.."
                  />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="subjects"
                label="Select Subject to Assign"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  value={selectedSubjectValue}
                  onChange={handleSelectSubjectChange}
                  optionFilterProp="children"
                  placeholder="Select Subjects"
                  showSearch
                  mode={"multiple"}
                  loading={loadingSubjectList}
                >
                  { subjectListByClass?.length>0 && subjectListByClass?.map((item:any) => (
                    <Select.Option key={item.subjectId} value={item.subjectId}>
                      {item.subjectName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button className="assign_class_btn" loading={loading} htmlType="submit">
                  {"Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default memo(AssignClassToTeacherModal);
