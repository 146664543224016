import { useCallback, useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import "../../styles/pages/classesOverview.css";
import { Button, Input, Table, Modal, Form, Space, Tag,Popconfirm } from "antd";
import { CiSearch } from "react-icons/ci";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
//@ts-ignore
import addstudent from "../../assets/addstudent.png";
import { debounce } from "lodash";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useAppSelector } from "../../store/hooks";
import { Flex, Spin,PopconfirmProps } from "antd";
import {
  addArchive,
  deleteClass,
  fetchByClassName,
  getAllClasses,
  removeSubjectFromClass,
  unArchive,
} from "../../store/services/class";
import GHeader from "../../components/GeneralHeader/GHeader";
import ClassModal from "../../components/Modals/modalClasses/modalClasses";
import { GrAdd } from "react-icons/gr";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { TiDelete } from "react-icons/ti";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";
import store from "../../store";
import {
  setClasses,
  setLoadingClasses,
} from "../../store/slices/Classes/classSlice";

const style: React.CSSProperties = {
  width: '300vw',
  height: '300vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ClassesOverview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [className, setClassName] = useState<string | null>(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const { classes, count, loading } = useAppSelector((state) => state.class);
  const [currentFilter, setCurrentFilter] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);

  useEffect(() => {
    fetchAllClasses();
  }, [currentPage]);

  useEffect(() => {
  
    const class1: any = classes;
    setFilteredClasses(class1); 
  }, [classes]);

  const columns = (navigateToCurriculum: any) => [
    {
      title: "Classes",
      dataIndex: "className",
      key: "className",
      width: "22%",
      // render: (className: any, obj: any) => (
      //   <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      //     <p style={{ fontSize: "14px", position: "relative", left: "11px" }}>
      //       {`class-${obj?.classIndex}` || "N/A"}
      //     </p>
      //   </div>
      // ),
    },
    {
      title: "Next Class",
      dataIndex: "progressClassName",
      key: "progressClassName",
      width: "22%",
      render: (text: any) => (
        <p>
          {text || "N/A"}
        </p>
      ),
    },
    {
      title: "No. of Teachers",
      dataIndex: "teachers",
      key: "teachers",
      width: "20%",
      render: (teachers: any) => (
        <p style={{ margin: "0 auto", position: "relative", left: "42px" }}>
          {teachers || "N/A"}
        </p>
      ),
    },
    {
      title: "No. of Students",
      dataIndex: "students",
      key: "students",
      width: "22%",
      render: (students: any) => (
        <p style={{ margin: "0 auto", position: "relative", left: "42px" }}>
          {students || "N/A"}
        </p>
      ),
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      key: "subjects",
      width: "22%",
      render: (subjects: any[], record: any) => {
        if (!subjects || subjects.length === 0) {
          return (
            <Tag
              style={{
                width: "30px",
                padding: "5px",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "var(--secondary-color)",
                color: "white",
              }}
            >
              0
            </Tag>
          );
        }
        return (
          <Tooltip
            title={
              <div>
                {subjects?.map((item: any, index: any) => (
                  <Tag
                    key={index}
                    style={{
                      backgroundColor: "var(--primary-color)",
                      margin: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {item.subjectName}
                    <TiDelete
                      style={{
                        cursor: "pointer",
                      }}
                      size={20}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSubject(item, record);
                      }}
                    />
                  </Tag>
                ))}
              </div>
            }
            overlayInnerStyle={{ backgroundColor: "#F7F7F7", border: "none" }}
          >
            <Tag
              style={{
                width: "30px",
                padding: "5px",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "var(--secondary-color)",
                color: "white",
              }}
            >
              {subjects.length}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (text: any, record: any) => {
      
        return (
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {/* <GrAdd 
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e?.stopPropagation();
              navigateToCurriculum(record.classId);
            }}
          /> */}
            <FiEdit2
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={(e) => {
                e?.stopPropagation();
                setIsEdit(true);
                setData(record);
                setIsModalOpens(true);
              }}
            />

            {record.status === "active" ? (
              <Popconfirm
              style={style}
              title="Archive class"
              description="Are you sure add to archive ?"
              onConfirm={(e) => {  
                e?.stopPropagation();
                archive(record);
              }}
              onCancel={(e)=> e?.stopPropagation()}

              okText="Yes"
              cancelText="No"
            
            >
              <MdOutlineArchive
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              />
              </Popconfirm>
            ) : (
              <MdOutlineUnarchive
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                onClick={(e) => {
                  e?.stopPropagation();
                  removeArchive(record);
                }}
              />
            )}
            {/* <button disabled={true} className="delete_btn_disabled">
            <IoTrashOutline
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "not-allowed",
                opacity: 0.4,
              }}
              onClick={(e) => {
              deleteData(record.classId);
                e?.stopPropagation();
              }}
            />
          </button> */}
          </div>
        );
      },
    },
  ];

  const fetchAllClasses = async (filter = currentFilter) => {
    const res = await getAllClasses({
      page: currentPage,
      perPage: 8,
      search: className ?? "",
      status: filter,
    });
  };

  const archive = async (record: any) => {
    const payload = {
      classId: record.classId,
    };
    const data = await addArchive(payload);
    fetchAllClasses(currentFilter)
  };
  const removeArchive = async (record: any) => {
    const payload = {
      classId: record.classId,
    };
    const data = await unArchive(payload);
    fetchAllClasses()
  };

  const handleCancel = () => {
    setIsModalOpens(false);
  };

  const navigateToCurriculum = (classId: number) => {
    navigate(`/curriculum`, {
      state: {
        classId: classId,
      },
    });
  };

  const fetchClass = () => {
    fetchAllClasses();
  };

  const deleteData = async (id: any) => {
    try {
      const res = await deleteClass(id);
      if (res?.status === 200) {
        fetchAllClasses();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const SearchByClassName = async () => {
  //   try {
  //     const payload = {
  //       page: currentPage,
  //       perPage: 7,
  //       className: className,
  //     };
  //     const res = await fetchByClassName(payload);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const SearchByClassName = async () => {
    try {
      // Only proceed if className is not null or empty
      if (!className || className.trim() === "") {
        const res = await getAllClasses({
          page: currentPage,
          perPage: 8,
          search: className ?? "",
        });
        setFilteredClasses(res.records);
        console.log("Class name is null or empty", res.record);
        console.log("Class name is null or empty");
        return;
      }

      const payload = {
        page: currentPage,
        perPage: 7,
        search: className,
      };
      console.log("Payload:", payload);

      const res = await fetchByClassName(payload);
      console.log("Response records:", res.records);
      setFilteredClasses(res.records);

      // Filter results for exact match based on classIndex
      const exactMatches = res.records.filter((classItem: any) => {
        console.log("classItem.classIndex:", classItem.classIndex);
        console.log("search className:", className);

        // Compare the search term to classIndex rather than className
        return `class-${classItem.classIndex}` === className.trim();
      });

      console.log("Exact matches:", exactMatches);
      // setFilteredClasses(exactMatches);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      SearchByClassName();
    }, 1000),
    [className]
  );

  useEffect(() => {
    if (className) {
      debouncedSearch();
      return () => {
        debouncedSearch.cancel();
      };
    } else {
      fetchAllClasses();
    }
  }, [className, debouncedSearch]);
  useEffect(() => {
    // selectValue('active');
  }, []);

  const removeSubject = async (subject: any, class_: any) => {
    const payload = { subjectId: subject.subjectId, classId: class_.classId };
    await removeSubjectFromClass(payload);
    fetchClass();
  };

  const selectValue = async (e: any) => {
    setCurrentPage(1)
    setCurrentFilter(e);
    const data = await getAllClasses({
      page: currentPage,
      perPage: 8,
      search: className ?? "",
      status: e,
    });
  };
  
  return checkRoutePermissions("classesPage") ? (
    <>
      <ClassModal
        setIsModalOpens={setIsModalOpens}
        isModalOpens={isModalOpens}
        isEdit={isEdit}
        modalData={data}
        setIsEdit={setIsEdit}
        fetchClass={fetchClass}
        count={count}
      />
      {/* <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
        centered
      >
        <img src={addstudent} alt="logo" className="add-classes-logo" />
        <div className="modalheading">
          <h1 className="add-class-styling">Add Class</h1>
          <div className="pdiv">
            <p className="modoalp fill-out-styling">
              Fill out the following information to add a new class to the
              system.
            </p>
          </div>
        </div>
        <div className="form">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="className"
              label="Class Name"
              className="labels adjust-alignment"
              rules={[{ required: true, message: "Class Name is required" }]}
            >
              <Input className="labelinput" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button className="cancelbtn" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button htmlType="submit" className="addbtn">
                  Add
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal> */}

      <div className="mainDiv responsive">
        <div className="mainss classesTable">
          <div className="main_parent">
            <div>
              <GHeader
                heading="Classes Overview"
                headingText=""
                numberOfPeople={`${count} Classes`}
                buttonText="Add a Class"
                onClick={() => {
                  setIsEdit(false)
                  setIsModalOpens(true)}}
              />
              <div style={{ marginTop: "8px" }} className="fliters_container">
                <Input
                  placeholder="Search..."
                  onChange={(e) => {
                    setClassName(e.target.value);
                  }}
                  prefix={
                    <CiSearch
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#667085",
                      }}
                    />
                  }
                  className="filter_search"
                />
                <Select
                  defaultValue="All Classes"
                  style={{ width: 120, height: 45 }}
                  onChange={(e: any) => selectValue(e)}
                  options={[
                    { value: "", label: "All Classes" },
                    { value: "archive", label: "Archive" },
                    { value: "active", label: "Active" },
                  ]}
                />
              </div>
            </div>
            <div className="mian_tab" style={{ marginTop: "10px" }}>
              {loading ? (
                <Flex
                  align="center"
                  justify="center"
                  style={{ height: "50vh" }}
                  gap="middle"
                >
                  <Spin size="large" />
                </Flex>
              ) : (
                <div className="table_container">
                  <Table
                    className="custom_table_design"
                    dataSource={
                      // filteredClasses.length > 0 ?
                       filteredClasses 
                      //  : classes
                    }
                    style={{ flex: 1 }}
                    columns={columns(navigateToCurriculum)}
                    pagination={false}
                    onRow={(record, rowIndex) => ({
                      onClick: (event) => {
                        navigate(`/classes-progress/${record?.classId}`);
                      },
                    })}
                    scroll={{ x: 600 }}
                    rowKey={"classId"}
                  />
                </div>
              )}
            </div>
            <div className="main_pagination">
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={count}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotAccess />
  );
};

export default ClassesOverview;
