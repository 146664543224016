import { Progress, Spin } from "antd";
import React from "react";

const HistoricalLevel = ({ loading, year, setYear, progress }: any) => {
  const years = [
    {
      year: "Year 1",
      _id: 1,
    },

    {
      year: "Year 2",
      _id: 2,
    },

    {
      year: "Year 3",
      _id: 3,
    },

    {
      year: "Year 4",
      _id: 4,
    },

    {
      year: "Year 5",
      _id: 5,
    },

    {
      year: "Year 6",
      _id: 6,
    },

    {
      year: "Year 7",
      _id: 7,
    },

    {
      year: "Year 8",
      _id: 8,
    },

    {
      year: "Year 9",
      _id: 9,
    },
  ];

  return (
    <>
      <div className="tab_learning_text">Historical Level</div>
      <div className="learning_tab">
        <div className="learning_btn_tabs_parent">
          {years?.length > 0 &&
            years.map((item: any) => {
              return (
                <div
                  onClick={() => {
                    setYear(item?._id);
                  }}
                  className={`learn_btn_tabs_child ${
                    item._id === year && "learn_btn_tabs_child_active"
                  } `}
                >
                  {item?.year}
                </div>
              );
            })}
        </div>
      </div>

      <div className="level_learning_tab custom_scroll_bar">
        {loading ? (
          <div className="history_loading_box">
            <Spin className="student_custom_spinner" />
          </div>
        ) : (
          progress?.map((item: any, index: number) => (
            <div key={index} className="level_learning_child">
              {/* <p>Level at the beginning </p> */}
              <div className="level_learning_child_btn">{item?.name}</div>
              <Progress
                strokeColor="var(--secondary-color)"
                percent={Number((Number(item?.percent) * 100).toFixed(0))}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default HistoricalLevel;
