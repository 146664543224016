import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCurrentDate,
  setCurrentWeekDay,
} from "../../store/slices/dashboardSlice/teacherDashboardSlice";
import { dateFormat } from "../../utils";
import { current } from "@reduxjs/toolkit";
import { getTeacherSchedule } from "../../store/services/schedule";
import { getSchduleByTeacher } from "../../store/services/dashboard";

interface Props { }

const WeekCalendar: React.FC<Props> = ({ }: any) => {
  const { currentWeekDay, currentDate } = useAppSelector(
    (state) => state.teacherDashboard
  );
  const user = useAppSelector(
    (state) => state.auth.user
  );
  const [weekDays, setWeekDays] = useState<any>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const _days = generateWeekDays();
    setWeekDays(_days);
  }, [currentWeekDay]);

  const generateWeekDays = () => {
    return Array.from({ length: 7 }, (_, i) =>
      moment(currentWeekDay).clone().startOf("week").add(i, "days")
    );
  };

  const getDate = new Date();


  const currentMonth = getDate.toLocaleString('default', { month: 'long' });
  const currentYear = getDate.getFullYear();


  const fetchSchedule = async (date:string)=>{
    try {
      const res = await getSchduleByTeacher({date:date,teacherId:Number(user?.id)});
    } catch (error) {
      console.log("error",error);
    }
  }


  useEffect(()=>{
    if (currentDate) {
      console.log("date working");
      fetchSchedule(currentDate);
    }
  },[currentDate]);



  return (
    <div className="_weekCalendarContainer">
      <div className="_weekCalendarHeader">
        <MdOutlineArrowBackIos
          color="rgba(102, 112, 133, 1)"
          style={{ cursor: "pointer" }}
          onClick={() => {
           
            dispatch(
              setCurrentWeekDay(
                moment(currentWeekDay)
                  .clone()
                  .startOf("week")
                  .subtract(1, "week")
                  .startOf("week")
                  .format(dateFormat)
              )
            );
          }}
        />
        <p>{`${currentMonth} ${currentYear}`}</p>

        <MdOutlineArrowForwardIos
          color="rgba(102, 112, 133, 1)"
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(
              setCurrentWeekDay(
                moment(currentWeekDay)
                  .clone()
                  .startOf("week")
                  .add(1, "week")
                  .startOf("week")
                  .format(dateFormat)
              )
            );
          }}
        />
      </div>
      <div className="_dateBox">
        {weekDays?.map((v: Date, i: number) => {
          return (
            <div
              key={i}
              className={`_dateCard ${moment(v).format(dateFormat) === currentDate
                  ? "_activeDate"
                  : ""
                }`}
              onClick={() =>{
                dispatch(setCurrentDate(moment(v).format(dateFormat)));

              }
                
              }
            >
              <p>{moment(v).format("dddd").slice(0, 1)}</p>
              <div>
                <p>{moment(v).format("DD")}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekCalendar;
