import React, { useEffect, useState } from "react"; 
import { Button, Input, message, Upload, Spin, Form } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { MdOutlineDelete } from "react-icons/md";
import { LuUploadCloud } from "react-icons/lu";
import { useAppSelector } from "../../store/hooks";
import { userUpdateProfile } from "../../store/services/settings";
import { getSingleUser } from "../../store/services/adminLogin";

const { Dragger } = Upload;

const Yourprofile = ({ user }: any) => {
  const [loading, setLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<any>(null);

  const [form] = Form.useForm();

  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: () => {},
    showUploadList: false,
    onChange(info) {
      console.log("info?.file", info?.file);
      setProfilePhoto(info?.file?.originFileObj);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      });
    }
  }, [user, form]);

  const updateProfileHandler = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const formData = new FormData();
      formData.append("id", user?.id);
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);

      if (profilePhoto) {
        formData.append("pictures", profilePhoto);
      }

      await userUpdateProfile(formData);
      await getSingleUser();
      setLoading(false);
      setProfilePhoto(null);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="yourprofilemain">
      <div className="profileAndButtons">
        <div className="yourprofile">
          <h2>Your Profile</h2>
          <p>Update your profile photo and details here.</p>
        </div>
        <div className="buttons">
          <Button>Cancel</Button>
          <Button onClick={updateProfileHandler} loading={loading} disabled={loading}>
            Save
          </Button>
        </div>
      </div>
      <hr />

      {loading ? (
        <div
          style={{
            marginTop: "5rem",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <Form form={form} layout="vertical">
            <div className="Nameinputdata">
              <div>
                <h2 className="name">First Name</h2>
              </div>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                  {
                    min: 2,
                    message: "First name must be at least 2 characters long",
                  },
                ]}
              >
                <Input className="InputField" placeholder="First name" />
              </Form.Item>
            </div>

            <div className="Nameinputdata">
              <div>
                <h2 className="name">Last Name</h2>
              </div>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                  {
                    min: 2,
                    message: "Last name must be at least 2 characters long",
                  },
                ]}
              >
                <Input className="InputField" placeholder="Last name" />
              </Form.Item>
            </div>

            <hr />

            <div className="Nameinputdata">
              <div>
                <h2 className="email">Email</h2>
                <p>This will be displayed on your profile.</p>
              </div>
              <Form.Item name="email">
                <Input className="InputField" placeholder="johan@gmail.com" disabled />
              </Form.Item>
            </div>
          </Form>

          <div className="uploadimag">
            <div>
              <h2 className="email">Profile Photo</h2>
            </div>
            {profilePhoto ? (
              <div className="uploaded_image_container">
                <div className="image_container">
                  <img src={URL.createObjectURL(profilePhoto)} alt="profile" />
                </div>

                <div className="icon_container" onClick={() => setProfilePhoto(null)}>
                  <MdOutlineDelete />
                </div>
              </div>
            ) : (
              <div className="Dragger">
                <Dragger {...props} className="custom_dragger">
                  <p className="ant-upload-drag-icon">
                    <LuUploadCloud className="uploadicon" />
                  </p>
                  <p className="ant-upload-text">
                    <strong>Click to upload</strong> or drag and drop
                  </p>
                  <p className="ant-upload-hint">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </p>
                </Dragger>
              </div>
            )}
          </div>

          <div className="buttonsmobile">
            <Button onClick={updateProfileHandler} loading={loading} disabled={loading}>
              Save
            </Button>
            <Button>Cancel</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Yourprofile;
