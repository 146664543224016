import { useEffect, useState } from "react";
import { Button, Spin, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import { getSubjectByClass } from "../../../store/services/subject";
import { firstLetterCap } from "../../../utils";

import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import SingleStudentCard from "./SingleStudentCard";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setActiveSubject,
  setCurrentIndex,
  setCurrentLevel,
  setLevels,
} from "../../../store/slices/Classes/classSlice";
import { getAllStudentsByClassSubject } from "../../../store/services/student";
import {
  createProgress,
  getLevelBySubject,
  getStudentProgressByLevel,
} from "../../../store/services/class";
import { setStudentsByClassSubject } from "../../../store/slices/students/studentSlice";
import {
  LessonType,
  ModuleType,
  ResetStatusType,
  StatusType,
} from "../../../types/interface/level";
import ProgressNoteModal from "../../../components/Modals/ProgressNoteModal";
import { BiRefresh } from "react-icons/bi";

const MobileView = () => {
  const { subjects, activeSubject, levels, currentLevel, currentIndex } =
    useAppSelector((state) => state.class);
  const { studentsByClassSubject } = useAppSelector((state) => state.student);
  const [isLoading, setIsLoading] = useState<string>("");
  const dispatch = useAppDispatch();
  const { classId } = useParams();
  const [updateStatus, setUpdateStatus] = useState<Array<StatusType>>([]);
  const [noteModal, setNoteModal] = useState<boolean>(false);

  const [noteModalData, setNoteModalData] = useState<any>(null);

  useEffect(() => {
    _getInitial();

    const cleanup = () => {
      dispatch(setActiveSubject(null));
    };

    return cleanup;
  }, [classId]);

  useEffect(() => {
    // _getData();
  }, [activeSubject]);

  const _getData = async (_subject: any) => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId && (_subject || activeSubject)) {
      setIsLoading("level");
      const subjectId = _subject?.subjectId || activeSubject?.subjectId;
      await getAllStudentsByClassSubject({ classId, subjectId });
      const _level = await getLevelBySubject({ subjectId });
      if (_level?.length > 0)
        await getStudentProgressByLevel({
          levelId: _level?.[0]?.levelId,
        });
      setIsLoading("");
    }
  };

  const _getInitial = async () => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId) {
      setIsLoading("initial");
      const _subjects = await getSubjectByClass({ classId });
      if (_subjects?.subjectList?.length > 0) {
        dispatch(setActiveSubject(_subjects?.subjectList?.[0]));
        const subjectId = _subjects?.subjectList?.[0]?.subjectId;
        await getAllStudentsByClassSubject({ classId, subjectId });
        const _level: any = await getLevelBySubject({ subjectId });
        if (_level?.length > 0)
          await getStudentProgressByLevel({
            levelId: _level?.[0]?.levelId,
          });
      }
      setIsLoading("");
    }
  };

  const handleChangeLevel = async (levelId: number) => {
    window.scrollTo(200, 200);
    dispatch(setCurrentLevel([]));
    await getStudentProgressByLevel({
      levelId,
    });
  };

  const _getProgress = async (levelId: number) => {
    setIsLoading("level");
    await getStudentProgressByLevel({
      levelId,
    });
    setIsLoading("");
  };

  const getColor = (value: string | null | undefined) => {
    switch (value) {
      case "pass":
        return "rgb(83, 194, 37)";
      case "fail":
        return "rgba(234, 67, 54, 1)";
      case "in-progress":
        return "rgba(255, 217, 102, 1)";
      default:
        return "#fff";
    }
  };

  const _handleStatusUpdate = (obj: StatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId
    );
    if (index !== -1) {
      temp.splice(index, 1, obj);
    } else {
      temp.push(obj);
    }
    setUpdateStatus(temp);
  };

  const _handleStatusReset = (obj: ResetStatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId
    );
    if (index !== -1) {
      temp.splice(index, 1);
    }
    setUpdateStatus(temp);
  };

  const handleSaveProgress = async () => {
    setIsLoading("progress");
    const res = await createProgress({ progress: updateStatus });
    if (res) {
      updateStatus.length > 0 && (await _getProgress(updateStatus[0].levelId));
      setUpdateStatus([]);
    }
    setIsLoading("");
  };

  const handleOpenNoteModal = (obj: any) => {
    setNoteModal(true);
    setNoteModalData(obj);
  };
  const handleCancelNoteModal = () => {
    setNoteModal(false);
    setNoteModalData(null);
  };

  return (
    <div className="_mProgressContainer">
      <div className="_progressHeader">
        <div className="_headingBox">
          <h2>Classes</h2>
        </div>
      </div>
      <div className="_subjectContainer">
        {subjects?.map((subj) => {
          return (
            <div
              onClick={() => {
                dispatch(setActiveSubject(subj));
                _getData(subj);
              }}
              key={subj?.subjectId}
              className={
                activeSubject?.subjectId === subj?.subjectId
                  ? "_subjectButtonActive"
                  : "_subjectButton"
              }
            >
              <p>{firstLetterCap(subj?.subjectName)}</p>
            </div>
          );
        })}
      </div>
      {isLoading === "initial" ? (
        <div className="_loadingSpinner">
          <Spin />
        </div>
      ) : subjects?.length > 0 ? (
        <>
          <SingleStudentCard
            student={studentsByClassSubject[currentIndex]}
            search
          />
          <section style={{ height: "381px" }}>
            <div className="_mProgressSection">
              <div className="_levelContainer">
                {levels?.map((v: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="_levelBox"
                      style={{
                        backgroundColor: `rgb(${v?.color})`,
                        borderBottomLeftRadius: i === 0 ? "10px" : 0,
                      }}
                      onClick={() => handleChangeLevel(v?.levelId)}
                    >
                      <span>{v?.levelName}</span>
                    </div>
                  );
                })}
              </div>
              <div className="_levelContent">
                <div
                  className="_lcHeader"
                  style={{
                    backgroundColor: `rgb(${
                      levels?.find(
                        (lev: any) =>
                          lev?.levelId === currentLevel?.[0]?.levelId
                      )?.color
                    })`,
                  }}
                >
                  <p>
                    {
                      levels?.find(
                        (lev: any) =>
                          lev?.levelId === currentLevel?.[0]?.levelId
                      )?.levelName
                    }
                  </p>
                </div>
                <div className="_levelContentBody">
                  {currentLevel?.map((module: ModuleType, i: number) => {
                    return (
                      <div key={i} className="_hCard">
                        <div className="_hCardHeader">
                          <p>{module?.moduleName}</p>
                        </div>
                        <div className="_hCardContent">
                          {module?.lessons?.map(
                            (lesson: LessonType, i: number) => {
                              const bg = i % 2 == 1;
                              return (
                                <div
                                  key={i + lesson?.lessonName}
                                  className="_vCard"
                                >
                                  <div
                                    className="_vCardHeader"
                                    style={{
                                      backgroundColor: bg
                                        ? "rgba(250, 251, 253, 1)"
                                        : "#fff",
                                    }}
                                  >
                                    <p>
                                      {lesson?.lessonName?.length > 35
                                        ? `${lesson?.lessonName?.slice(
                                            0,
                                            35
                                          )}...`
                                        : lesson?.lessonName}
                                    </p>
                                  </div>
                                  {[studentsByClassSubject[currentIndex]]?.map(
                                    (v, i) => {
                                      const _studentForUpdate =
                                        updateStatus?.find(
                                          (x) =>
                                            x?.lessonId === lesson?.lessonId &&
                                            x?.studentId === v?.studentId &&
                                            x?.levelId === module?.levelId &&
                                            x?.moduleId === module?.moduleId
                                        );

                                      const _student = lesson?.progress?.find(
                                        (x) => v?.studentId == x?.studentId
                                      );
                                      const _obj: any = {
                                        lessonId: lesson?.lessonId,
                                        studentId: v?.studentId,
                                        levelId: module?.levelId,
                                        moduleId: module?.moduleId,
                                      };

                                      const isPassButton =
                                        _studentForUpdate?.status === "pass" ||
                                        _student?.status === "pass";
                                      const isFailButton =
                                        _studentForUpdate?.status === "fail" ||
                                        _student?.status === "fail";
                                      const isInProgressButton =
                                        _studentForUpdate?.status ===
                                          "in-progress" ||
                                        _student?.status === "in-progress";

                                      return (
                                        <Tooltip
                                          placement="top"
                                          title={
                                            <div className="_statusButtonBox">
                                              {!isPassButton && (
                                                <Button
                                                  style={{
                                                    background:
                                                      getColor("pass"),
                                                  }}
                                                  onClick={() =>
                                                    _handleStatusUpdate({
                                                      ..._obj,
                                                      status: "pass",
                                                    })
                                                  }
                                                >
                                                  Pass
                                                </Button>
                                              )}
                                              {!isFailButton && (
                                                <Button
                                                  style={{
                                                    background:
                                                      getColor("fail"),
                                                  }}
                                                  onClick={() =>
                                                    _handleStatusUpdate({
                                                      ..._obj,
                                                      status: "fail",
                                                    })
                                                  }
                                                >
                                                  Fail
                                                </Button>
                                              )}
                                              {!isInProgressButton && (
                                                <Button
                                                  style={{
                                                    background:
                                                      getColor("in-progress"),
                                                  }}
                                                  onClick={() =>
                                                    _handleStatusUpdate({
                                                      ..._obj,
                                                      status: "in-progress",
                                                    })
                                                  }
                                                >
                                                  In Progress
                                                </Button>
                                              )}
                                              {_studentForUpdate?.status && (
                                                <BiRefresh
                                                  size={30}
                                                  color={"#fff"}
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    _handleStatusReset({
                                                      ..._obj,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </div>
                                          }
                                        >
                                          <div
                                            key={i}
                                            className="_studentColorBox"
                                            style={{
                                              backgroundColor: getColor(
                                                _studentForUpdate?.status ||
                                                  _student?.status
                                              ),
                                            }}
                                            onDoubleClick={() =>
                                              handleOpenNoteModal({
                                                value: v,
                                                studentId: v?.studentId,
                                                studentName: `${v?.firstName} ${v?.lastName}`,
                                                imageUrl: v?.imageUrl,

                                                className: v?.className,

                                                levelId: module?.levelId,

                                                subjectId: v?.subjectId,
                                                subjectName: subjects?.find(
                                                  (x) =>
                                                    x?.subjectId ===
                                                    v?.subjectId
                                                )?.subjectName,

                                                lessonId: lesson?.lessonId,
                                                lessonName: lesson?.lessonName,

                                                progress:
                                                  _studentForUpdate || _student,
                                              })
                                            }
                                          >
                                            {_student?.notes && (
                                              <div
                                                className="_cornerNoteSign"
                                                style={{
                                                  borderTop: `10px solid ${
                                                    isPassButton
                                                      ? "rgba(8, 93, 58, 1)"
                                                      : isInProgressButton
                                                      ? "rgba(181, 71, 8, 1)"
                                                      : "transparent"
                                                  }`,
                                                }}
                                              />
                                            )}
                                          </div>
                                        </Tooltip>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
          {updateStatus?.length > 0 && (
            <div className="_mobileSaveBtnBox">
              <Button
                loading={isLoading === "progress"}
                onClick={handleSaveProgress}
              >
                Save Progress
              </Button>
              <Button
                onClick={() => {
                  setUpdateStatus([]);
                }}
              >
                Clear
              </Button>
            </div>
          )}
          <div className="_nextPrevButton">
            <Button
              onClick={() =>
                dispatch(
                  setCurrentIndex({
                    type: "decrement",
                    length: studentsByClassSubject?.length,
                  })
                )
              }
            >
              <IoMdArrowBack
                style={{ marginRight: "5px", marginBottom: "-3px" }}
                size={17}
              />{" "}
              Previous Student
            </Button>
            <Button
              onClick={() =>
                dispatch(
                  setCurrentIndex({
                    type: "increment",
                    length: studentsByClassSubject?.length,
                  })
                )
              }
            >
              Next Student{" "}
              <IoMdArrowForward
                style={{ marginLeft: "5px", marginBottom: "-3px" }}
                size={17}
              />
            </Button>
          </div>
        </>
      ) : (
        <div className="_emptyText">
          <p>No subject found!</p>
        </div>
      )}
      <ProgressNoteModal
        open={noteModal}
        close={handleCancelNoteModal}
        data={noteModalData}
      />
    </div>
  );
};

export default MobileView;
