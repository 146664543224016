import { Input, Select } from "antd";
import StudentPerformanceCard from "../../components/Cards/StudentPerformanceCard";
import GHeader from "../../components/GeneralHeader/GHeader";
import { RiExpandUpDownLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { useEffect, useState } from "react";
import { GoHome } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { getTopFerformer } from "../../store/services/state";
import { useAppSelector } from "../../store/hooks";
import useDebounce from "../../Hook/useDebounce";
import { getAllSubjects } from "../../store/services/class";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useLocation } from "react-router-dom";

function DashboardTopPerformers() {
  const [activeItem, setActiveItem] = useState({
    subjectId: null,
    activeTab: "All subjects",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { topForformer, loading } = useAppSelector((state) => state.states);
  const [subjects, setSubjects] = useState([]);
  const location = useLocation();
  const [student, setStudent] = useState([]);
  const [filterState, setFilterState] = useState({
    search: null,
    subjectId: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getSubjects = async () => {
      const response = await getAllSubjects();
      setSubjects(response);
    };
    getSubjects();
  }, []);

  console.log("location", location.state);

  useEffect(() => {
    const getAboveTargetStudent = async () => {
      console.log(activeItem.subjectId);
      if (activeItem.subjectId === null) {
        await getTopFerformer(filterState?.search, null, null);
      } else {
        await getTopFerformer(filterState?.search, null, activeItem?.subjectId);
      }
    };
    getAboveTargetStudent();
  }, [filterState, activeItem]);

  const debouncedFunction = useDebounce((value: string, label: string) => {
    setFilterState((prev) => ({
      ...prev,
      [label]: value,
    }));
  }, 1000);

  const list = subjects && [
    {
      subjectId: null,
      subjectName: "All subjects",
    },
    ...subjects.map((sub: { subjectId: number; subjectName: string }) => sub),
  ];


  useEffect(() => {
    if (location) {
      setStudent(location?.state?.topPerformer);
    }
  }, [location])

  return (
    <div className="mainDiv responsive">
      <div
        className="_breadcrumb"
        style={{
          marginBottom: 40,
          display: "flex",
          alignItems: "center",
          gap: 3,
        }}
      >
        <GoHome
          size={20}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />{" "}
        <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
        <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          Dashboard
        </span>{" "}
        <IoIosArrowForward color="rgba(234, 236, 240, 1)" />{" "}
        <p
          style={{
            color: "#fff",
            backgroundColor: "var(--secondary-color)",
            padding: "7px",
            borderRadius: "7px",
          }}
        >
          Top Performers
        </p>
      </div>
      <GHeader
        heading="Top Performers"
        headingText="Review and search top performers among students."
      />
      <div className="Custom_tabs_containers">
        {list?.map((tab: any, index: any) => {
          return (
            <div
              key={index}
              className={`${activeItem?.activeTab === tab.subjectName
                  ? "custom_active_tab"
                  : "custom_tab"
                } custom-tab-all `}
              onClick={() =>
                setActiveItem({
                  subjectId: tab.subjectId,
                  activeTab: tab.subjectName,
                })
              }
            >
              {tab.subjectName}
            </div>
          );
        })}
      </div>
      <div className="fliters_container">
        <Input
          placeholder="Search..."
          onChange={(e) => debouncedFunction(e.target.value, "search")}
          prefix={
            <CiSearch
              style={{ width: "20px", height: "20px", color: "#667085" }}
            />
          }
          className="filter_search"
        />
      </div>
      <div className="performers_main_container">
        <div className="cards">
          {student?.length > 0 ? student?.slice(0, 3).map((item: any) => {
            return (
              <StudentPerformanceCard key={item?.id} data={item} />
            );
          }) : (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "10%",
                fontSize: 20,
              }}
            >
              <p>No data available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardTopPerformers;
