import { privateAPI } from "../../config/constants";


export const getLevelProgress = async(classId:number , subjectId:number )=>{
    try {
        const res = await privateAPI.get(`/learning/get-current-level-progress/${classId}/${subjectId}`);
        if(res.status === 200){
            return res.data;
        }
    } catch (error) {
        console.log("error monitoring",error);
    }
}

export const getTargetsToReachNextLevel = async(classId:number , subjectId:number )=>{
    try {
        const res = await privateAPI.get(`/learning/get-targets-for-next-level/${classId}/${subjectId}`);
        if(res.status === 200){
            return res.data;
        }
    } catch (error) {
        console.log("error monitoring",error);
    }
}

export const getLearningByYear = async(year:number, classId:number , subjectId:number )=>{
    try {
        const res = await privateAPI.get(`/learning/get-by-year/${year}/${classId}/${subjectId}`);
        if(res.status === 200){
            return res.data;
        }
    } catch (error) {
        console.log("error monitoring",error);
    }
}