import React, { useEffect, useState } from "react";
import NotAccess from "../NotAccess";
import { checkRoutePermissions } from "../../utils/permission";
import "../../styles/pages/learning.css";
import { Progress, Table } from "antd";
import SubjectLevel from "../../components/subjectLevel/SubjectLevel";
import TargetToReach from "../../components/targetToReach/TargetToReach";
import HistoricalLevel from "../../components/HistoricalLevel/HistoricalLevel";
import {
  getLearningByYear,
  getLevelProgress,
  getTargetsToReachNextLevel,
} from "../../store/services/learning";
import { getStudentSubjects } from "../../store/services/class";
import { useAppSelector } from "../../store/hooks";

const Learning = () => {
  const { user }: any = useAppSelector((state) => state?.auth);
  console.log("user", user);
  const [year, setYear] = useState(1);
  const [subjects, setSubjects] = useState([]);
  const [activeSubject, setActiveSubject] = useState<any>();
  const [currentLevelProgress, setCurrentLevelProgress] = useState<any>(null);
  const [targets, setTargets] = useState<any>(null);
  const [yearlyProgress, setYearlyProgress] = useState<any>(null);
  const [targetsLoading, setTargetLoading] = useState<boolean>(true);
  const [currentLevelLoading, setCurrentLevelLoading] = useState<boolean>(true);
  const [yearlyProgressLoading, setYearlyProgressLoading] =
    useState<boolean>(true);

  useEffect(() => {
    console.log("currentLevelProgress", currentLevelProgress);
  }, [currentLevelProgress]);

  useEffect(() => {
    setActiveSubject(subjects[0]);
  }, [subjects]);

  useEffect(() => {
    const getSubjects = async () => {
      const res = await getStudentSubjects();
      console.log("res", res);
      setSubjects(res?.subjects);
    };

    getSubjects();
  }, []);

  useEffect(() => {
    const getLearning = async () => {
      setYearlyProgressLoading(true);
      const res = await getLearningByYear(
        year,
        user?.ClassId,
        activeSubject?.subjectId
      );
      if (res) {
        setYearlyProgress(res?.progress);
        setYearlyProgressLoading(false);
      }
    };
    if (activeSubject) {
      getLearning();
    }
  }, [year, activeSubject]);

  useEffect(() => {
    const getCurrentLevelProgress = async () => {
      setCurrentLevelLoading(true);
      const res = await getLevelProgress(
        user?.ClassId,
        activeSubject?.subjectId
      );
      if (res) {
        setCurrentLevelProgress(res?.data);
        setCurrentLevelLoading(false);
      }
    };

    const getTargets = async () => {
      setTargetLoading(true);
      const res = await getTargetsToReachNextLevel(
        user?.ClassId,
        activeSubject?.subjectId
      );
      if (res) {
        setTargets(res?.data);
        setTargetLoading(false);
      }
    };

    if (activeSubject) {
      getCurrentLevelProgress();
      getTargets();
    }
  }, [activeSubject]);

  return checkRoutePermissions("learningPage") ? (
    <div className="mainDiv responsive">
      <h2 className="head_learning">Learning</h2>
      <p className="learning_text">Review and manage the schools data.</p>
      <div className="leaning_parent">
        {subjects?.length > 0 &&
          subjects?.map((item: any) => {
            return (
              <div
                key={item.subjectId}
                onClick={() => {
                  setActiveSubject(item);
                  // setKey(item.subjectId);
                  // setName(item.subjectName);
                }}
                className={`btn_leaning ${
                  item.subjectId === activeSubject?.subjectId
                    ? "btn_leaning_active"
                    : ""
                }`}
              >
                {item.subjectName}
              </div>
            );
          })}
      </div>
      <div className="learing_Child">{activeSubject?.subjectName}</div>
      {/* /////     subject level   */}
      <div className="leaning_card">
        <SubjectLevel
          percent={Number(
            (Number(currentLevelProgress?.progress) * 100).toFixed(0)
          )}
          level="Current level"
          link="#"
          btnData="10% vs last month"
          btn={true}
          currentLevel={currentLevelProgress?.currentLevel?.levelName}
          loading={currentLevelLoading}
        />
        <SubjectLevel
          percent={
            currentLevelProgress?.nextLevel?.levelIndex == 1
              ? 99
              : Number(
                  (Number(currentLevelProgress?.progress) * 100).toFixed(0)
                )
          }
          // currentLevel="Level 2"
          currentLevel={currentLevelProgress?.nextLevel?.levelIndex == 1 ? currentLevelProgress?.currentLevel?.levelName : currentLevelProgress?.nextLevel?.levelName}
          level="Target Level By End Of Year"
          loading={currentLevelLoading}
        />
      </div>

      {/* //////// Target to React  ///////// */}
      <div className="table_container learning_tab">
        <TargetToReach
          loading={targetsLoading}
          nextLevel={targets?.nextLevel?.levelName}
          tableData={targets?.pending?.map((item: any) => {
            return {
              name: targets?.currentLevelDesc?.levelName,
              curriculum: item?.lessonName,
              // sub: "Letters",
            };
          })}
        />
      </div>

      {/* //////////////// Historical Level */}
      <div className="tab_learning_header">
        <HistoricalLevel
          year={year}
          setYear={setYear}
          progress={yearlyProgress}
          loading={yearlyProgressLoading}
        />
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Learning;
