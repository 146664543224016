import { Progress } from "antd";
import React from "react";

interface Props {
  data: any;
}
const ResultCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="_resultCard" style={{ backgroundColor: data?.bgColor }}>
      <p style={{ color: data?.textColor }}>{data?.title}</p>
      <Progress
        type="circle"
        percent={data?.percent}
        size={40}
        strokeWidth={10}
        strokeColor={data?.progressColor}
      />
    </div>
  );
};

export default ResultCard;
