import React from 'react'
import progresstracker from '../../assets/Blogo.png'
import acedemicHat from '../../assets/acedemic-hat.png'
import { Row, Col } from 'antd'
const LoginHeader = () => {
    return (
        <div
            className='login-header'
        >
            {/* <Row>
                <Col xs={24} md={12} lg={12}> */}
                    <div
                        className='logo'
                    >
                        <div className='logo-div'>


                            <img
                                src={progresstracker}
                                className="changeForResponsive"
                                style={{ width: "230px" }}
                                alt=""
                            />
                            <img
                                src={acedemicHat}
                                className="responsiveLogo"
                                alt=""
                            // style={{ width: "150px", height: "auto" }}
                            />
                        </div>
                    </div>
                {/* </Col>
                <Col xs={0} md={12} lg={12}>
                </Col>
            </Row> */}

        </div>
    )
}

export default LoginHeader