import { privateAPI, publicAPI } from "../../config/constants";
import store, { AppDispatch } from ".."; // Adjust the path to your store
import {
  setAllStudents,
  setLoadingStudents,
  setStudentBriefAllNotes,
  setStudentsByClassSubject,
} from "../slices/students/studentSlice";
import { setCurrentIndex } from "../slices/Classes/classSlice";
import { notification } from "antd";

// Gety all student

export const getAllStudents = async (data:{
  page:any,perPage:any,progress?:boolean,search?:any,passOut?:boolean
}) => {
  try {
    store.dispatch(setLoadingStudents(true));
    const response = await privateAPI.post("student/get-all",data);
    console.log("response",response);
    if (response) {
      store.dispatch(setAllStudents(response.data.lists));
      console.log("Getting all students =>", response.data);
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoadingStudents(false));
  }
};
export const getAllStudentsByTeacher = async (
  page?: any,
  perPage?: any,
  classId?: any
) => {
  try {
    store.dispatch(setLoadingStudents(true));
    const response = await privateAPI.post("teacher/get-students-by-teacher", {
      page,
      perPage,
    });
    if (response) {
      store.dispatch(setAllStudents(response.data.lists));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoadingStudents(false));
  }
};

// Get student
export const getStudent = async (payload: any) => {
  try {
    store.dispatch(setLoadingStudents(true));
    const response = await publicAPI.post(`student/get-category`, payload);
    if (response) {
      store.dispatch(setAllStudents(response.data));
      console.log(response.data);
    }
    return response?.data;
  } catch (error) {
    console.log("Error fetching students:", error);
  } finally {
    store.dispatch(setLoadingStudents(false));
  }
};

export const getAllStudentsByClassSubject = async (payload: any) => {
  try {
    const response = await publicAPI.post(
      `student/get-all-students-by-class-subject`,
      payload
    );
    if (response?.data?.students?.length > 0) {
      store.dispatch(setStudentsByClassSubject(response?.data?.students));
      if (response?.data?.students?.length > 0)
        store.dispatch(setCurrentIndex(0));
    }
    return response?.data;
  } catch (error) {
    console.log("Error fetching students:", error);
  }
};

export const createStudentBriefCategory = async (formData: any) => {
  try {
    const response = await privateAPI.post("/category/create", formData);
    return response.data;
  } catch (error) {
    console.error("Error creating student brief category:", error);
    throw error;
  }
};

export const createStudentNote = async (data: {
  studentId: number;
  categoryId: number;
  note: string;
}): Promise<{
  studentId: number;
  categoryId: number;
  note: string;
} | void> => {
  try {
    const response = await privateAPI.post("/note/create", data);
    return response.data;
  } catch (error) {
    console.error("Error creating student Note:", error);
    throw error;
  }
};

export const deleteStudentNote = async (noteId: number) => {
  try {
    const res = await privateAPI.post("/note/delete", { noteId });
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

export const getAllStudentNote = async (studentId: number) => {
  try {
    const res = await privateAPI.post("/note/get-all", { studentId });
    if (res?.data) {
      store.dispatch(setStudentBriefAllNotes(res?.data?.noteList));
    }
    return res.data;
  } catch (error) {
    console.log("error get student note", error);
  }
};

export const UpdateStudentNote = async (data: {
  noteId: number;
  note: string;
}) => {
  try {
    const res = await privateAPI.post("/note/update", data);
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

/////////// assign student

export const assignStudents = async (data: any) => {
  try {
    const res = await privateAPI.post("/student/assign-class-subject", data);
    console.log("res", res.status === 200);
    if (res.status === 200) {
      notification.success({ message: "Assigned successfully" });
      return res;
    } else {
      notification.error({ message: "Not assigned something went wrong" });
    }
  } catch (error: any) {
    if (error.response && error.response.data) {
      notification.error({
        message: "Error",
        description: error.response.data.message || "Something went wrong!",
      });
    } else {
      notification.error({
        message: "Error",
        description: error.message || "Something went wrong!",
      });
    }
  }
};

export const deleteStudent = async (id: number) => {
  try {
    const res = await privateAPI.post("/student/delete", { id: id });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const filterStudents = async (data: any) => {
  try {
    const res = await privateAPI.post("/student/search-student", data);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const searchStundentByTeacher = async (data: any) => {
  try {
    const res = await privateAPI.post(
      "/teacher/get-students-by-search-teacher",
      data
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const fetchClassBySearch = async (data: any) => {
  try {
    const res = await privateAPI.post("/class/get-all", data);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getSubject = async (data: any) => {
  try {
    const res = await privateAPI.post("/subject/get-subjects-from-class", data);
    console.log("Res", res);
    return res;
  } catch (error) {
    console.log("error", error);
  }
};
