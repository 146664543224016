import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Space, notification } from "antd";
import addstudent from "../../assets/addstudent.png";
import {
  createCurriculumApi,
  updateCurriculum,
} from "../../store/services/curriculum";
import { useAppSelector } from "../../store/hooks";
import { CurriculumType } from "../../types/interface/curriculum";
import SearchableDropdown from "../SearchableDropdown";

interface AddCurriculumProps {
  isCurriculvisible: boolean;
  handleCancel: () => void;
  fetchCurriculum: () => void;
  editMode?: boolean;
  editObj?: CurriculumType | null;
  modalData?:any
}

const AddCurriculumTabModal: React.FC<AddCurriculumProps> = ({
  isCurriculvisible,
  handleCancel,
  fetchCurriculum,
  editMode,
  editObj,
  modalData,
}) => {
  const [form] = Form.useForm<CurriculumType>();
  const subjectId = useAppSelector((state) => state.subject.currentSubjectId);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressClassId, setprogressClassId] = useState("");

  useEffect(() => {
    if (editMode && editObj) {
      setprogressClassId(editObj.classId)
      form.setFieldsValue({
        classId: editObj?.class,
        focus: editObj?.focus,
        practices: editObj.practices,
        primary: editObj?.primary,
        primaryq: editObj?.primaryq,
      });
    }
    console.log(form)
  }, [editMode]);

  const onFinish = async (values: CurriculumType) => {
    // if (curriculumId === null) {
    //   notification.error({ message: "No subject selected" });
    //   return;
    // }

    const payload: any = {
      classId:progressClassId,
      mindsetToFocusOn: values.focus,
      practicesTopicsToFocusOn: values.practices,
      primaryFocusSubject: values.primary,
      primaryFocusQuaidahQuran: values.primaryq,
    };

    if (editMode) {
      payload.curriculumId = editObj?.curriculumId;
    }

    setLoading(true);
    const resp = editMode
      ? await updateCurriculum(payload)
      : await createCurriculumApi(payload);

    if (resp) {
      form.resetFields();
      handleCancel();
      fetchCurriculum();
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isCurriculvisible}
      className="custom-modal"
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}
      footer={null}
      centered
    >
      <img src={addstudent} alt="logo" />
      <div className="modalheading">
        <h1>{editMode ? "Update Maqaasid" : "Add Maqaasid"}</h1>
      </div>
      <div className="form">
        <Form
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={onFinish}
        >
          {/* <Form.Item name="class" label="Class" className="labels" rules={[{ required: true }]}>
            <Input className="labelinput" />
            </Form.Item> */}
            <SearchableDropdown
             url="/class/get-all"
                mode={false}
                placeholder="progress class"
                columns={["className"]}
                type="className"
                defaultValue={editMode ? progressClassId : undefined}
                onChange={(e: any) => setprogressClassId(e)}
                 />

          <Form.Item
            name="focus"
            label="Mindset to focus on"
            className="labels"
            rules={[{ required: true }]}
          >
            <Input className="labelinput" />
          </Form.Item>

          <Form.Item
            name="practices"
            label="Practices/ Topics to Focus on"
            className="labels"
            rules={[{ required: true }]}
          >
            <Input className="labelinput" />
          </Form.Item>

          <Form.Item
            name="primary"
            label="Primary Focus Subject"
            className="labels"
            rules={[{ required: true }]}
          >
            <Input className="labelinput" />
          </Form.Item>

          <Form.Item
            name="primaryq"
            label="Primary Focus Quaidah"
            className="labels"
            rules={[{ required: true }]}
          >
            <Input size="middle" className="labelinput" />
          </Form.Item>

          <Form.Item>
            <Space className="curriculum_btn">
              <Button className="cancelbtn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button loading={loading} disabled={loading} htmlType="submit" className="addbtn">
                {editMode ? "Update" : "Add"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCurriculumTabModal;
