import React from "react";
import { Col, Row } from "antd";
import ResultCard from "../../components/ResultCard";
import TargetCard from "../../components/TargetCard";
import AvatarRoundIcons from "../../components/AvatarRoundIcons";

import { LuInfo } from "react-icons/lu";
import { BsTelephone } from "react-icons/bs";
import ProgressCard from "../../components/ProgressCard";
import SingleStudentCard from "../classesOverview/ClassesProgress/SingleStudentCard";
import { useAppSelector } from "../../store/hooks";

const StudentDashboard: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  let _performance = "";
  let _underperformance = "Underperformance";
  let _overperformance = "Overperformance";

  return (
    <div className="_studentDashboardWrapper">
      <div className="_studentDashboard">
        {/* ----------> Left Section <----------*/}
        <div className="_leftSection">
          <div className="_leftSectionWrapper">
            <h2 className="_mainHeading">
              Welcome back, {`${user?.firstName || "-"}`}{" "}
              {`${user?.lastName || "-"}`}! 👋🏼
            </h2>
            <p className="_description">Review the schools data.</p>

            <div className="_studentCard">
              <SingleStudentCard student={user} overperformance />
            </div>
            {/* Chart Section */}
            <div className="_chartSection">
              <div className="_chartBox">
                <p className="_chartHeading">Level</p>
                <div className="_chart"></div>
              </div>
              <div className="_chartBox">
                <p className="_chartHeading">Perfomance</p>
                <div className="_chart"></div>
              </div>
            </div>

            {/* Latest Exam Result Section */}
            <div className="_latestExamResult">
              <div className="_resultHeader">
                <h2>Latest Exam Result</h2>
                <div>
                  <img src={require("../../assets/calender.png")} alt="Icon" />
                  <p>Jan 6, 2024</p>
                </div>
              </div>
              <div className="_resultBody">
                <Row gutter={[20, 20]}>
                  {[
                    {
                      title: "Duas",
                      percent: 90,
                      bgColor: "rgba(236, 253, 243, 1)",
                      progressColor: "rgba(166, 239, 103, 1)",
                      textColor: "rgba(2, 122, 72, 1)",
                    },
                    {
                      title: "Practical",
                      percent: 20,
                      bgColor: "rgba(254, 243, 242, 1)",
                      progressColor: "rgba(217, 45, 32, 1)",
                      textColor: "rgba(217, 45, 32, 1)",
                    },
                    {
                      title: "Tajweed",
                      percent: 40,
                      bgColor: "rgba(255, 250, 235, 1)",
                      progressColor: "rgba(247, 178, 122, 1)",
                      textColor: "rgba(181, 71, 8, 1)",
                    },
                  ].map((v, i) => {
                    return (
                      <Col key={i} xs={24} xl={8}>
                        <ResultCard data={v} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>

            {/* Latest Achieved Targets */}
            <div className="_latestAchievedTargets">
              <div className="_targetHeader">
                <h2>Latest Achieved Targets</h2>
              </div>
              <div className="_targetBody">
                {[
                  {
                    lessonName: "All Letters in different variations",
                    createdAt: new Date().toISOString(),
                    subjectName: "Tajweed",
                  },
                  {
                    lessonName: "All Letters in different variations",
                    createdAt: new Date().toISOString(),
                    subjectName: "Duas",
                  },
                  {
                    lessonName: "All Letters in different variations",
                    createdAt: new Date().toISOString(),
                    subjectName: "Practical",
                  },
                ].map((v, i) => {
                  return <TargetCard key={i} data={v} index={i} />;
                })}
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }} />
        </div>

        {/* ----------> Right Section <----------*/}
        <div className="_rightSection">
          <div className="_studentDetails">
            <h2>Student Profile</h2>
            <div className="_studentImage">
              <img
                src={
                  user?.imageUrl
                    ? user?.imageUrl
                    : require("../../assets/avatar_student.png")
                }
                alt={"Student"}
              />
              <AvatarRoundIcons />
            </div>
            <div className="_studentNameBox">
              <h3>
                {user?.firstName} {user?.lastName}
              </h3>
              {_performance === _underperformance ? (
                <div className="_underperformance">
                  <LuInfo style={{ marginBottom: "-2px" }} /> Underperfomance
                </div>
              ) : _performance === _overperformance ? (
                <div className="_overperformance">
                  <LuInfo style={{ marginBottom: "-2px" }} /> Overperfomance
                </div>
              ) : (
                ""
              )}
            </div>
            <p>Student</p>
            <div className="_classAndContact">
              <p>
                <LuInfo style={{ marginBottom: "-2px", marginRight: "3px" }} />{" "}
                {user?.className || "N/A"}
              </p>
              <p>
                <BsTelephone
                  style={{ marginBottom: "-2px", marginRight: "3px" }}
                />{" "}
                {user?.phoneNumber || "N/A"}
              </p>
            </div>
          </div>
          <div className="_classesProgressSection">
            <h2>Classes Progress</h2>
            <div className="_classesProgressBox">
              {[
                {
                  subjectName: "Qaidah / Quran",
                  teacherName: "Mr. Rachel",
                  percent: 90,
                },
                {
                  subjectName: "Tajweed",
                  teacherName: "Mr. Rachel",
                  percent: 70,
                },
                {
                  subjectName: "An Nasihah",
                  teacherName: "Mr. Rachel",
                  percent: 100,
                },
                {
                  subjectName: "Surahs",
                  teacherName: "Mr. Rachel",
                  percent: 30,
                },
                {
                  subjectName: "Duas",
                  teacherName: "Mr. Rachel",
                  percent: 60,
                },
                {
                  subjectName: "Practical",
                  teacherName: "Mr. Rachel",
                  percent: 60,
                },
              ].map((v, i) => {
                return <ProgressCard key={i} data={v} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
