import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputRef, Modal, notification, Select } from "antd";
import { IoIosAdd } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { createTarget } from "../../../store/services/curriculum";


interface ClassModalProps {
  setIsModalOpens: (isOpen: boolean) => void;
  isModalOpens: boolean;
  currentModule: string;
  bool?: boolean;
  onAddTarget: [];
  getData: () => void;
}
interface Module {
  moduleId: number;
  moduleName: string;
  levelId: number;
}


type MaterialType = string;

const TargetModelAdmin: React.FC<ClassModalProps> = ({
  setIsModalOpens,
  isModalOpens,
  currentModule,
  onAddTarget,
  getData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [material, setMaterials] = useState<MaterialType[]>([]);
  const [mater, setMater] = useState<MaterialType>("");
  const [plans, setPlans] = useState<MaterialType[]>([]);
  const [resources, setResources] = useState<MaterialType[]>([]);
  const [links, setLinks] = useState<MaterialType[]>([]);
  const materialRef = useRef<InputRef>(null);
  const planRef = useRef<InputRef>(null);
  const resourcesRef = useRef<InputRef>(null);
  const linkRef = useRef<InputRef>(null);

  const handleCancel = () => {
    setIsModalOpens(false);
    form.resetFields();
    setPlans([]);
    setResources([]);
    setLinks([]);
    setMaterials([]);
  };
 
  // const sendDAta = async () => {
  //   try {
  //     const values = await form.validateFields();
  //     console.log("material",material);
  //     return;
  //     setLoading(true);
  //     setLoading(false);
  //     const data = {
  //       lessonName: values.lessonName,
  //       moduleId: currentModule,
  //       duration: Number(values.duration),
  //       unit: "min",
  //       materials: material,
  //       plans: plans,
  //       resources: resources,
  //       links: links,
  //     };


  //     const res = await createTarget(data);
  //     if (res) {
  //       handleCancel();
  //       getData();
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("error",error);
  //   }
  // };

  
  const sendDAta = async () => {
    try {
      const values = await form.validateFields();
      // console.log("*****************", values.Modules);
      // if (material.length === 0 || plans.length === 0 || resources.length === 0 || links.length === 0) {
      //   notification.error({ message: 'You have to filled all the form to prceed further' });
      //   return;
      // }
      // console.log("*****************", values.Modules);
      setLoading(true);

      const data = {
        lessonName: values.lessonName,
        moduleId: values.Modules,
        duration: Number(values.duration),
        unit: 'min',
        materials: material,
        plans: plans,
        resources: resources,
        links: links,
      };

      const res = await createTarget(data);
    
      if (res) {
        form.resetFields();
        notification.success({ message: 'Target created successfully!' });
        handleCancel();
         getData();
      } else {
        notification.error({ message: 'Failed to create target.' });
      }
    } catch (error) {
      console.error('Error:', error);
      notification.error({ message: 'An error occurred during the creation process.' });
    } finally {
      setLoading(false);
    }
  };

  const addMaterial = () => {
    if (materialRef.current && materialRef.current.input) {
      setMaterials([...material, materialRef.current.input.value]);
      materialRef.current.input.value = "";
    }
  };

  const addPlans = () => {
    if (planRef.current && planRef.current.input) {
      setPlans([...plans, planRef.current.input.value]);
      planRef.current.input.value = "";
    }
  };

  const addResources = () => {
    if (resourcesRef.current && resourcesRef.current.input) {
      setResources([...resources, resourcesRef.current.input.value]);
      resourcesRef.current.input.value = ""; // Clear input value
    }
  };

  const AddLink = () => {
    if (linkRef.current && linkRef.current.input) {
      setLinks([...links, linkRef.current.input.value]);
      linkRef.current.input.value = ""; // Clear input value
    }
  };

  const removeMaterialData = (index: number) => {
    const updatedMaterials = [
      ...material.slice(0, index),
      ...material.slice(index + 1),
    ];
    setMaterials(updatedMaterials);
  };
  const removePlans = (index: number) => {
    const updatedMaterials = [
      ...plans.slice(0, index),
      ...plans.slice(index + 1),
    ];
    setPlans(updatedMaterials);
  };
  const removeResources = (index: number) => {
    const updatedMaterials = [
      ...resources.slice(0, index),
      ...resources.slice(index + 1),
    ];
    setResources(updatedMaterials);
  };
  const removeLinks = (index: number) => {
    const updatedMaterials = [
      ...links.slice(0, index),
      ...links.slice(index + 1),
    ];
    setLinks(updatedMaterials);
  };

  return (
    <>
      <Modal
        title={
          <div
            style={{
              width: "40px",
              height: "40px",
              display: "grid",
              placeItems: "center",
              borderRadius: "50%",
              backgroundColor: "#EFF8FF",
            }}
          >
            <IoIosAdd fontSize={"26px"} color="blue" />
          </div>
        }
        open={isModalOpens}
        onCancel={handleCancel}
        className="target"
        footer={null}
        centered
      >
        <div>
          <h2
            style={{
              fontSize: "18px",
              color: "#26252A",
              fontWeight: "600",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            Add a Target
          </h2>
          <p
            style={{
              color: "#667085",
              fontSize: "13px",
              fontWeight: "400",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            Review and manage the targets.
          </p>
          <div style={{ marginTop: "20px" }}>
            <Form form={form} layout="vertical">
              <Form.Item
                name="level"
                label="Select Level"
                rules={[{ required: true, message: "Please select a level" }]}
              >
                <Select placeholder="Select a level">
                  {onAddTarget.map((level:any) => (
                        //  console.log(level)
                         
                      <Select.Option key={level.levelId} value={level.level}>
                        {level.level}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="Modules"
                label="Modules"
                rules={[{ required: true, message: "Please select a level" }]}
              >
                <Select placeholder="Select Modules">
                  {onAddTarget?.map((modal:any) => (
                       modal.modules?.map((modals:any)=>(
                        <Select.Option key={modals.levelId} value={modals.moduleId}>
                        {modals.name}
                      </Select.Option>
                       ))
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="lessonName"
                label="Lesson Name"
                rules={[{ required: true, message: "Lesson name is required" }]}
              >
                <Input placeholder="Lesson Name (Required)" />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Time Duration"
                rules={[
                  { required: true, message: "Time duration is required" },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Time Duration in minutes (Required)"
                />
              </Form.Item>
              <Form.Item
                name="material"
                label="Materials"
                // rules={[{ required: true, message: "Material is required" }]}
              >
                <Input
                  value={mater}
                  ref={materialRef}
                  // onChange={(e) => setMater(e.target.value)}
                  placeholder="Material (Required)"
                />
              </Form.Item>
              <Button
                onClick={addMaterial}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {material.length > 0 &&
                  material.map((item, index) => (
                    <div className="points-row">
                      <li key={index}>{item}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removeMaterialData(index);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
              <Form.Item
                name="plans"
                label="Plans"
                // rules={[{ required: true, message: "Plans is required" }]}
              >
                <Input ref={planRef} placeholder="Plans (Required)" />
              </Form.Item>
              <Button
                onClick={addPlans}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {plans.length > 0 &&
                  plans.map((item, index) => (
                    <div className="points-row">
                      <li key={index}>{item}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removePlans(index);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
              <Form.Item
                name="resources"
                label="Resources"
                // rules={[{ required: true, message: "Resources is required" }]}
              >
                <Input ref={resourcesRef} placeholder="Resources (Required)" />
              </Form.Item>
              <Button
                onClick={addResources}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {resources.length > 0 &&
                  resources.map((item, index) => (
                    <div className="points-row">
                      <li key={index}>{item}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removeResources(index);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
              <Form.Item
                name="links"
                label="Links"
                // rules={[{ required: true, message: "Links is required" }]}
              >
                <Input ref={linkRef} placeholder="Links (Required)" />
              </Form.Item>
              <Button
                onClick={AddLink}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {links.length > 0 &&
                  links.map((item, index) => (
                    <div className="points-row">
                      <li key={index}>{item}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removeLinks(index);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
            </Form>
          </div>
          <div
            style={{
              display: "flex",
              gap: "15px",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ width: "120px", color: "#26252A" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              style={{
                width: "120px",
                backgroundColor: "#26252A",
                color: "white",
              }}
              onClick={sendDAta}
            >
              create
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TargetModelAdmin;
