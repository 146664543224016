import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

interface SpeedValue {
  data?: any;
  value: number;
  maxValue: number;
}

const SpeedoMeter: React.FC<SpeedValue> = ({ data ,value, maxValue }) => {

  const percentage = Math.round((value / maxValue) * 100);

  const reachedColor = "var(--secondary-color)";
  const remainingColor = "var(--primary-color)";

  const getSegmentColors = () => {
    return [
      reachedColor,
      remainingColor,
    ];
  };

  return (
    <div style={{ position: 'relative', width: '300px', height: '200px'}}>
      <ReactSpeedometer
        maxValue={100}
        value={value}
        needleColor='#000'
        segmentColors={getSegmentColors()}
        customSegmentStops={[0, value, maxValue]}
        textColor="#000"  
        ringWidth	={20}
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#000',
          textAlign: 'center',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        {percentage}%
      </div>
      <style>
        {`
          .speedometer-text, .speedometer-label, .speedometer-tick, .speedometer-axis {
            display: none !important;
          }
        `}
      </style>
    </div>
  );
}

export default SpeedoMeter;
